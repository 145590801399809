import React, { useState } from 'react';
import './Login.css';
import googleimg from '../assets/googlelogo.png';
import { auth, db } from '../firebaseConfig'; 
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useUserAuth } from '../UserAuthContext';
import { getDoc, setDoc, doc } from 'firebase/firestore';

const createUserDataInFirestore = async (userId, userEmail, userName) => {
    try {
        // Set user info in the 'userinfo' subcollection of 'Account'
        await setDoc(doc(db, "users", userId, "Account", "userinfo"), {
            name: userName,
            email: userEmail
        });

        // writing to numTokens not allowed by user
        // // Set the initial balance in the 'balance' subcollection of 'Account'
        // await setDoc(doc(db, "users", userId, "Account", "balance"), {
        //     numTokens: 0
        // });

        // // Placeholder for 'Drafts' collection
        // await setDoc(doc(db, "users", userId, "Drafts", "placeholder"), {
        //     placeholder: true
        // });

        // // Placeholder for 'Solved' collection
        // await setDoc(doc(db, "users", userId, "Solved", "placeholder"), {
        //     placeholder: true
        // });

    } catch (error) {
        console.error("Error writing user data to Firestore:", error);
    }
}


const Login = ({ onClose, onSignUpClick }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const { setUser } = useUserAuth();

    const handleLogin = async () => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            setUser({
                id: userCredential.user.uid,
                name: userCredential.user.displayName,
                email: userCredential.user.email,
            });
            onClose();
        } catch (err) {
            setError("Invalid email or password.");  // Generalized error message
        }
    };
    

    const handleGoogleLogin = async () => {
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);
    
            // Check if user exists in Firestore
            const userDoc = await getDoc(doc(db, "users", result.user.uid, "Account", "userinfo"));
            if (!userDoc.exists()) {
                // If user doesn't exist, create it
                await createUserDataInFirestore(result.user.uid, result.user.email, result.user.displayName);
            }
    
            setUser({
                id: result.user.uid,
                name: result.user.displayName,
                email: result.user.email,
            });
            onClose();
        } catch (err) {
            setError("Failed to login with Google. Please try again.");  // Generalized error message
        }
    };

    return (
        <div className="login-overlay">
            <div className="login-container">
                <button className="login-close" onClick={onClose}>
                    X
                </button>
                <div className="welcome-container">
                    <span className="welcome-text">Welcome to</span>
                    <br />
                    <span className="brand-name">QuickSolve</span>
                </div>

                <div className="input-group">
                    <label className="email-label">Email</label>
                    <input type="email" className="email-input" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" />
                </div>

                <div className="input-group">
                    <label className="password-label">Password</label>
                    <input type="password" className="password-input" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter your password" />
                    {error && <div style={{ color: 'red', fontSize: '14px' }}>{error}</div>}
                    <button className="forgot-password">forgot your password?</button>
                </div>

                <div className="centered-container">
                    <div className="login-button" onClick={handleLogin}>
                        <span>Log In</span>
                    </div>
                    <div className="separator">--- OR ---</div>
                    <button className="google-login" onClick={handleGoogleLogin}>
                        <img src={googleimg} alt="Google Logo" />
                        <span>Continue with Google</span>
                    </button>
                    <button className="signup-prompt" onClick={onSignUpClick}>
                        not on QuickSolve yet? <span className="bold-signup">Sign up</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Login;
