import React, { createContext, useContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const SessionContext = createContext();

export const useSession = () => {
  return useContext(SessionContext);
};

export const SessionProvider = ({ children }) => {
  const [sessionId, setSessionId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let storedSessionId = sessionStorage.getItem('sessionId');
    if (!storedSessionId) {
      storedSessionId = uuidv4();
      sessionStorage.setItem('sessionId', storedSessionId);
    }
    setSessionId(storedSessionId);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div style={{
          width: '100%',
          height: '100vh', // 100% of the viewport height
          position: 'fixed', // Fixed position
          top: 0, left: 0, // Starting at the top-left corner
          background: 'black',
          display: 'flex', // Using Flexbox
          justifyContent: 'center', // Center horizontally
          alignItems: 'center' // Center vertically
      }}>
          <div style={{
              color: 'white',
              fontSize: 60,
              fontFamily: 'Montserrat',
              fontWeight: 900,
              wordWrap: 'break-word'
          }}>
              QuickSolve.ai
          </div>
      </div>
  );  // or return null if you don't want to show anything
  }

  return (
    <SessionContext.Provider value={sessionId}>
      {children}
    </SessionContext.Provider>
  );
};
