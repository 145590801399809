import React from 'react';
import ReactDOM from 'react-dom/client';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const stripePromise = loadStripe('pk_test_51NwDlMKuFkSCVKkQs9Y6KSF00nCIoPn2utqw8XkKHVNwiNFa76CUdQkpzD0qqMRCNlhA3JSc2oo4gY75G41yfwlP00iltHjoHT');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </React.StrictMode>
);

reportWebVitals();
