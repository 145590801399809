import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import './Header.css'; // Assuming you have a Header.css file for styles


const Header = ({ toggleOverlay }) => {
  return (
    <div className="header">
      <div className="header-content">
        <div className="header-logo">
          <span className="header-logo-text header-logo-main">QuickSolve</span>
          <span className="header-logo-text header-logo-sub">.ai</span>
        </div>
        <div className="header-subtitle">
          Homework Help 😁 You NEED
        </div>
      </div>
      <button className="header-login" onClick={toggleOverlay}>
        <span className="header-login-text">Log In</span>
        <span className="header-login-icon"><FontAwesomeIcon icon={faRightToBracket} size="xs" /></span>
      </button>
    </div>
  );
};

export default Header;
