import React from 'react';

const SectionTitle = ({ boldText, normalText }) => {
    return (
        <div style={{ textAlign: 'left', backgroundColor: '#EFEFEF', padding: '0 5%', marginTop: '2.5%', marginBottom: '2%'}}>
            <span style={{ color: '#413A3A', fontSize: 24, fontFamily: 'Montserrat', fontWeight: 700, wordWrap: 'break-word' }}>
                {boldText}
            </span>
            <span style={{ color: '#413A3A', fontSize: 24, fontFamily: 'Montserrat', fontWeight: 500, wordWrap: 'break-word' }}>
                {normalText}
            </span>
        </div>
    );
}

export default SectionTitle;
