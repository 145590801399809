import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, signOut } from './firebaseConfig';

const UserAuthContext = createContext();

export const useUserAuth = () => {
    return useContext(UserAuthContext);
};

export const UserAuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);  // New state for loading
    const [loggedOut, setLoggedOut] = useState(false);

    useEffect(() => {
        // This observer returns the currently logged-in user (or null if no user is logged in)
        const unsubscribe = auth.onAuthStateChanged(authUser => {
            if (authUser) {
                // User is logged in
                setUser({
                    id: authUser.uid,
                    email: authUser.email,
                    // You can add more fields if needed
                });
            } else {
                // User is logged out
                setUser(null);
            }
            setLoading(false);  // Set loading to false regardless of user being null or not
        });

        // Clean up the listener on component unmount
        return () => unsubscribe();
    }, []);

    const logOut = () => {
        signOut(auth)
            .then(() => {
                console.log("Logged out successfully");
                setUser(null);
                setLoggedOut(true);  // Set loggedOut to true
            })
            .catch((error) => {
                console.error("Error logging out:", error);
            });
    };

    const value = {
        user,
        setUser,
        logOut,  // Adding the logout function to the context
        loading,
        loggedOut
    };

    return (
        <UserAuthContext.Provider value={value}>
            {children}
        </UserAuthContext.Provider>
    );
};
