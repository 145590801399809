import React, { useState, useEffect, useRef } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useSession } from '../SessionContext';
import { doc, onSnapshot, getFirestore, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { uploadBytes } from 'firebase/storage';
import { getStorage, ref, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { app, auth, db } from '../firebaseConfig';
import './ProblemSelection.css';
import HighlightAltSharpIcon from '@mui/icons-material/HighlightAltSharp';
import SelectAllSharpIcon from '@mui/icons-material/SelectAllSharp';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Rnd } from 'react-rnd';
import { v4 as uuidv4 } from 'uuid';
import loading from '../assets/loading.png'
import { useNavigate } from 'react-router-dom';
import Login from '../anonymous_components/Login';
import SignUp from '../anonymous_components/Signup';
import { loadStripe } from '@stripe/stripe-js';
import { dotSpinner } from 'ldrs';
import { spiral } from 'ldrs'

spiral.register()
dotSpinner.register();


const ProblemSelection = () => {
    const initialSessionId = useSession();
    const [user, setUser] = useState(null);
    const [previousUser, setPreviousUser] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const isDeletingRef = useRef(false);
    const [uniqueComponentId, setUniqueComponentId] = useState(null);
    const isAnonymous = !user;
    const sessionId = isAnonymous ? initialSessionId : null;
    const userId = isAnonymous ? sessionId : user.uid;
    const storagePath = isAnonymous ? `AnonymousUploads/${sessionId}` : `UserUploads/${userId}/${uniqueComponentId}`;
    const stripePromise = loadStripe('pk_live_51NwDlMKuFkSCVKkQNCLa5aIVanD9poZX7COE5GorgoXtpkO9LVrvX0dqunWSY5Q3xYReuZuWuX686eLDh9WoO6JL00ZkhZxlNa');
    const [isDeductingTokens, setIsDeductingTokens] = useState(false);


    // Watch for changes in the Firebase auth state
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate('/');
    };


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
            console.log('Auth state changed', currentUser);
            if (previousUser && !currentUser) {
                // User was logged in, but now they are logged out
                navigate('/');
            } else if (!previousUser && currentUser) {
                // User was anonymous, but now they are logged in
                console.log('Migrating anonymous data to user', currentUser.uid, initialSessionId);
                await migrateAnonymousDataToUser(currentUser.uid, initialSessionId);
            }
            setUser(currentUser);
            setPreviousUser(currentUser);  // Update previous user state
            setIsReady(true);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (isReady) {
            // Now you can use user and sessionId without worrying
            console.log("Storage Path:", storagePath);
        }
    }, [isReady, user, sessionId]);

    const [fileNames, setFileNames] = useState([]);
    const [selectedButton, setSelectedButton] = useState(null);
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [currentFocus, setCurrentFocus] = useState(0);
    const [fileURLs, setFileURLs] = useState([]);
    const [numTokens, setNumTokens] = useState(null);
    const [hasSufficientTokens, setHasSufficientTokens] = useState(true);
    const [showLogin, setShowLogin] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);


    // console.log("Session ID:", sessionId);
    // console.log("User:", user);
    // console.log("Prev user:", previousUser);
    // console.log("Is Anonymous:", isAnonymous);
    // console.log("User ID:", userId);
    // console.log("Unique Component ID:", uniqueComponentId);
    // console.log(storagePath);

    // drag select states
    const [selections, setSelections] = useState([]);
    const [isCreating, setIsCreating] = useState(false);
    const [currentSelection, setCurrentSelection] = useState(null);
    const [mouseDown, setMouseDown] = useState(false);
    const startPos = useRef({ x: 0, y: 0 });
    const buttonRef = useRef(null);
    const containerRef = useRef(null);
    const [cursor, setCursor] = useState('pointer');
    const rndRef = useRef(null);
    const [initialResizePosition, setInitialResizePosition] = useState(null);
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const [showNotBigEnoughPopup, setShowNotBigEnoughPopup] = useState(false);
    const imageRefs = useRef({});
    const MIN_WIDTH = 100;  
    const MIN_HEIGHT = 30;  
    const prevSelections = usePrevious(selections);
    const [croppedImageURLs, setCroppedImageURLs] = useState({});
    const [loadedImages, setLoadedImages] = useState([]);
    const numberOfImages = useRef(0);

    const generateAndUploadCroppedImage = async (newSelection) => {
        const correspondingImage = imageRefs.current[newSelection.imageIndex];
        if (!correspondingImage) return;

        const displayedImgWidth = correspondingImage.offsetWidth;
        const displayedImgHeight = correspondingImage.offsetHeight;

        const highResImage = new Image();
        highResImage.src = fileURLs[newSelection.imageIndex];

        await new Promise((resolve) => {
            highResImage.onload = resolve;
        });

        const scalingFactorWidth = highResImage.width / displayedImgWidth;
        const scalingFactorHeight = highResImage.height / displayedImgHeight;

        // Adjust the cropping coordinates and sizes
        const adjustedCrop = {
            x: newSelection.position.x * scalingFactorWidth,
            y: newSelection.position.y * scalingFactorHeight,
            width: newSelection.size.width * scalingFactorWidth,
            height: newSelection.size.height * scalingFactorHeight
        };

        let imageURL;

        try {
            imageURL = await getCroppedImgAndUpload(fileURLs[newSelection.imageIndex], adjustedCrop, newSelection.id);
            newSelection.imageURL = imageURL;
        } catch (error) {
            console.error("Error in generating cropped image for drag-selected area:", error);
            return;
        }

        // Update the selection in the state with the image URL
        setSelections(prevSelections => {
            return prevSelections.map(sel => {
                if (sel.id === newSelection.id) {
                    return { ...sel, imageURL: imageURL };
                }
                return sel;
            });
        });
    };

    useEffect(() => {
        const storedId = sessionStorage.getItem('uniqueComponentId');
        if (storedId) {
            setUniqueComponentId(storedId);
        }
    }, []);

    useEffect(() => {
        const fetchSelectionsFromStorage = async () => {
            try {
                const storage = getStorage(app);
                const storageRef = ref(storage, `${storagePath}/Selections/selections.json`);
                const downloadURL = await getDownloadURL(storageRef);

                const response = await fetch(downloadURL);
                const data = await response.json();
                setSelections(data);
                console.log("Selections fetched from Firebase:", data);
            } catch (error) {
                console.error("Failed to fetch selections:", error);
            }
        };
        if (isReady) {
            fetchSelectionsFromStorage();
        }
    }, [sessionId, isReady, isAnonymous, uniqueComponentId]);

    useEffect(() => {
        const uploadSelectionsToStorage = async (selections) => {
            try {
                const storage = getStorage(app);
                const storageRef = ref(storage, `${storagePath}/Selections/selections.json`);

                const blob = new Blob([JSON.stringify(selections)], { type: "application/json" });



                await uploadBytes(storageRef, blob);
            } catch (error) {
                console.error("Failed to upload selections:", error);
            }
        };

        if (prevSelections && selections.length > 0 && isReady) {
            uploadSelectionsToStorage(selections);
        }
    }, [selections, prevSelections]);

    useEffect(() => {
        const handleResize = () => {
            // Only work with selections of the current focus.
            const focusedSelections = selections.filter(sel => sel.imageIndex === currentFocus);

            const updatedSelections = focusedSelections.map(sel => {
                // Safely check if the current image reference exists before accessing its properties.
                const currentImage = imageRefs.current[sel.imageIndex];
                if (!currentImage) return sel;  // If the image doesn't exist, return the original selection.

                const imgWidth = currentImage.offsetWidth;
                const imgHeight = currentImage.offsetHeight;

                const actualPosition = {
                    x: sel.ratio.x * imgWidth,
                    y: sel.ratio.y * imgHeight
                };

                const actualSize = {
                    width: sel.ratio.width * imgWidth,
                    height: sel.ratio.height * imgHeight
                };

                return {
                    ...sel,
                    position: actualPosition,
                    size: actualSize
                };
            });

            // Merge the updated selections with the ones that haven't changed.
            const mergedSelections = [...selections];
            updatedSelections.forEach(updatedSel => {
                const index = mergedSelections.findIndex(sel => sel.id === updatedSel.id);
                if (index !== -1) {
                    mergedSelections[index] = updatedSel;
                }
            });

            setSelections(mergedSelections);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [selections, currentFocus]);




    useEffect(() => {
        let docRef;

        if (!isAnonymous && (!userId || !uniqueComponentId)) {
            console.log("userId or uniqueComponentId is not set yet.");
            return;
        }

        if (isAnonymous && !sessionId) {
            console.log("sessionId is not set yet.");
            return;
        }

        if (isAnonymous) {
            // For anonymous users
            docRef = doc(getFirestore(), "anonymous", sessionId);
        } else {
            // For logged-in users, fetch their draft
            docRef = doc(getFirestore(), "users", userId, "Drafts", uniqueComponentId);

            // set tokens (only allowed to read)
            const docRef2 = doc(db, 'users', userId, 'Account', 'balance');
            getDoc(docRef2)
                .then((docSnapshot) => {
                    if (docSnapshot.exists()) {
                        setNumTokens(docSnapshot.data().numTokens || 0);
                    } else {
                        setNumTokens(0);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching token balance:', error);
                });
        }

        const unsubscribe = onSnapshot(docRef, (docSnap) => {
            if (docSnap.exists()) {
                const fileData = docSnap.data();
                setFileNames(fileData ? fileData.files || [] : []);
            }
        });

        return () => unsubscribe();

    }, [isAnonymous, sessionId, userId, uniqueComponentId]);


    useEffect(() => {
        if (isReady) {
            async function fetchURLs() {
                console.log("Fetching URLs from Storage Path:", storagePath);
                const storage = getStorage(app);
                const urls = await Promise.all(fileNames.map(async name => {
                    const fileRef = ref(storage, `${storagePath}/CurrentUploads/${name}`);
                    return await getDownloadURL(fileRef);
                }));
                setFileURLs(urls);
            }

            if (fileNames.length > 0) {
                fetchURLs();
            }
        }

    }, [fileNames]);

    useEffect(() => {
        if (selectedButton === 'all') {
            const fetchAndSetAllImageSelection = async () => {
                const currentImage = imageRefs.current[currentFocus];
                if (currentImage) {
                    const width = currentImage.offsetWidth;
                    const height = currentImage.offsetHeight;

                    // Remove all selections for the current image first
                    const newSelections = selections.filter(sel => sel.imageIndex !== currentFocus);

                    // Calculate the next problemNumber using remaining selections
                    const maxProblemNumber = Math.max(0, ...newSelections.map(sel => sel.problemNumber || 0));
                    const nextProblemNumber = maxProblemNumber + 1;

                    // Create the full image selection with the determined problemNumber
                    const newSelection = {
                        id: uuidv4(),
                        imageIndex: currentFocus,
                        position: { x: 0, y: 0 },
                        size: { width, height },
                        ratio: { x: 0, y: 0, width: 1, height: 1 }, // Covering the entire image
                        problemNumber: nextProblemNumber, // Use the determined problemNumber
                    };

                    // Push the created selection into the newSelections array
                    newSelections.push(newSelection);

                    // Reassign problem numbers
                    const updatedSelections = reassignProblemNumbers(newSelections);

                    setSelections(updatedSelections);

                    await generateAndUploadCroppedImage(newSelection);

                    // Deselect the "Select All" button
                    setSelectedButton(null);

                    // Prevent users from creating a new selection using the mouse
                    setIsCreating(false);

                    // Cancel any active Rnd selection
                    setCurrentSelection(null);
                }
            };

            fetchAndSetAllImageSelection();
        }
    }, [selectedButton]);

    useEffect(() => {
        const handleResize = () => {
            const currentImage = imageRefs.current[currentFocus];
            if (currentImage) {
                resizeContainer(currentImage);
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [currentFocus]);

    useEffect(() => {
        const currentImage = imageRefs.current[currentFocus];
        if (currentImage) {
            const imgWidth = currentImage.offsetWidth;
            const imgHeight = currentImage.offsetHeight;

            const focusedSelections = selections.filter(sel => sel.imageIndex === currentFocus);
            const updatedSelections = focusedSelections.map(sel => {
                const actualPosition = {
                    x: sel.ratio.x * imgWidth,
                    y: sel.ratio.y * imgHeight
                };

                const actualSize = {
                    width: sel.ratio.width * imgWidth,
                    height: sel.ratio.height * imgHeight
                };

                return {
                    ...sel,
                    position: actualPosition,
                    size: actualSize
                };
            });

            setSelections(prev => {
                const unchangedSelections = prev.filter(sel => sel.imageIndex !== currentFocus);
                return [...unchangedSelections, ...updatedSelections];
            });
        }
    }, [currentFocus]);


    const handleMouseDown = (e) => {
        e.preventDefault();
        if (e.target.tagName !== 'IMG') { // Check if the target is not an image
            if (isCreating) {
                setSelectedButton(null);
                setIsCreating(false);
                setShowCancelPopup(true); // Show the canceled popup

                // After 1 second, start the fade-out animation
                setTimeout(() => {
                    const popup = document.querySelector('.cancel-popup');
                    popup.classList.add('fade-out');

                    // Once the fade-out animation is complete, hide the popup
                    popup.addEventListener('animationend', () => {
                        popup.style.display = 'none';
                        popup.classList.remove('fade-out'); // Remove the fade-out class to reset the animation state
                        setShowCancelPopup(false);  // Reset the state for the popup
                    }, { once: true }); // Ensure the event listener is only called once
                }, 500);

            }
            return;
        }
        setMouseDown(true);
        console.log("Mouse down.");
        if (e.target === buttonRef.current) return;
        if (!isCreating) return;

        const currentItem = e.target.closest('.carousel-item'); // Get the closest carousel-item parent
        const rect = currentItem.getBoundingClientRect();

        const x = e.clientX - rect.left + window.scrollX;  // add horizontal scroll position
        const y = e.clientY - rect.top + window.scrollY;     // add vertical scroll position

        console.log("MouseDown Position:", { x, y });

        startPos.current = { x, y };
        setCurrentSelection({
            position: { x, y, left: x, top: y },
            size: { width: 0, height: 0 }
        });
    };

    const handleMouseUp = async () => {
        console.log(isDeletingRef.current)
        if (isDeletingRef.current) {
            console.log('Deletion in progress, delaying image set.');
            return;
        }
        setMouseDown(false);
        console.log("Mouse up.");
        if (currentSelection) {
            const currentImage = imageRefs.current[currentFocus];
            const imgWidth = currentImage.offsetWidth;
            const imgHeight = currentImage.offsetHeight;

            console.log("Current image dimensions on handleMouseUp:", { imgWidth, imgHeight });

            const ratio = {
                x: currentSelection.position.x / imgWidth,
                y: currentSelection.position.y / imgHeight,
                width: currentSelection.size.width / imgWidth,
                height: currentSelection.size.height / imgHeight
            };

            const nextProblemNumber = Math.max(...selections.map(s => s.problemNumber || 0), 0) + 1;

            const newSelection = {
                ...currentSelection,
                id: uuidv4(),
                imageIndex: currentFocus,
                ratio: ratio,
                problemNumber: nextProblemNumber,  // New attribute
            };


            if (newSelection.size.width < MIN_WIDTH || newSelection.size.height < MIN_HEIGHT) {
                setShowNotBigEnoughPopup(true);

                // After 1 second, start the fade-out animation
                setTimeout(() => {
                    const popup = document.querySelector('.not-big-enough-popup');
                    popup.classList.add('fade-out');

                    // Once the fade-out animation is complete, hide the popup
                    popup.addEventListener('animationend', () => {
                        popup.style.display = 'none';
                        popup.classList.remove('fade-out'); // Remove the fade-out class to reset the animation state
                        setShowNotBigEnoughPopup(false);  // Reset the state for the popup
                    }, { once: true }); // Ensure the event listener is only called once
                }, 500);

                setCurrentSelection(null);  // Reset the current selection if not big enough
                return;
            }
            setSelections(prev => [...prev, newSelection]);
            console.log("Rnd Element Position:", newSelection.position);
            console.log("Selection created:", newSelection);
            setCurrentSelection(null);

            setSelectedButton(null);
            await generateAndUploadCroppedImage(newSelection);
        }
        setIsCreating(false);
        setCursor('pointer');
    };



    const handleMouseMove = (e) => {
        if (isCreating && mouseDown) {
            const currentItem = e.target.closest('.carousel-item'); // Get the closest carousel-item parent

            // Check if currentItem is not null
            if (!currentItem) return;

            const rect = currentItem.getBoundingClientRect();

            const mouseX = e.clientX - rect.left;
            const mouseY = e.clientY - rect.top;

            const width = mouseX - startPos.current.x;
            const height = mouseY - startPos.current.y;

            const left = Math.min(mouseX, startPos.current.x);
            const top = Math.min(mouseY, startPos.current.y);

            setCurrentSelection({
                position: {
                    x: left, y: top, left, top
                },
                size: {
                    width: Math.abs(width),
                    height: Math.abs(height)
                }
            });
        }
    }

    const handleRemoveSelection = async (id) => {
        console.log("Attempting to remove selection with ID:", id);
        isDeletingRef.current = true; // Set deletion flag

        try {
            const storage = getStorage(app);
            const imageRef = ref(storage, `${storagePath}/Selections/Images/${id}.jpeg`); // Assuming jpeg format for now

            // Check if the file exists before attempting to delete
            const doesFileExist = await getDownloadURL(imageRef).then(() => true).catch(() => false);
            if (doesFileExist) {
                console.log("Deleting image at path:", `${storagePath}/Selections/Images/${id}.jpeg`);
                await deleteObject(imageRef);
                console.log(`Image for selection id ${id} (assumed .jpeg) deleted from Firebase Storage.`);

                // Update selections state only after confirming deletion
                setSelections(prev => {
                    const updated = prev.filter(sel => sel.id !== id);
                    return reassignProblemNumbers(updated);
                });

                isDeletingRef.current = false; // Set deletion flag
            } else {
                console.log(`No image found in Firebase Storage for ID ${id}.`);
                isDeletingRef.current = false; // Set deletion flag
            }
        } catch (error) {
            console.error("Error during deletion from Firebase Storage for ID " + id + ":", error);
        }
    };



    const reassignProblemNumbers = (selectionArray) => {
        selectionArray.sort((a, b) => a.problemNumber - b.problemNumber).forEach((sel, idx) => {
            sel.problemNumber = idx + 1;
        });
        return selectionArray;
    };



    // cancels default web image drag
    const preventDragHandler = (e) => {
        e.preventDefault();
    };

    function usePrevious(value) {
        const ref = useRef();

        useEffect(() => {
            ref.current = value;
        }, [value]);

        return ref.current;
    }

    const resizeContainer = (img) => {
        const parentDiv = img.closest('.carousel-item');
        if (parentDiv) {
            const ratio = img.naturalHeight / img.naturalWidth;

            // Calculate dimensions based on viewport width
            const widthFromVW = 0.7 * window.innerWidth;
            const heightFromVW = widthFromVW * ratio;

            // Calculate dimensions based on available height
            const heightFromVH = 0.8 * 0.89 * window.innerHeight;
            const widthFromVH = heightFromVH / ratio;

            // Determine which dimension is smaller
            if (widthFromVW * heightFromVW < widthFromVH * heightFromVH) {
                parentDiv.style.width = `${widthFromVW}px`;
                parentDiv.style.height = `${heightFromVW}px`;
            } else {
                parentDiv.style.width = `${widthFromVH}px`;
                parentDiv.style.height = `${heightFromVH}px`;
            }
        }
    };

    const getCroppedImgAndUpload = async (imageSrc, crop, selId) => {
        console.log(isDeletingRef.current)
        if (isDeletingRef.current) {
            console.log('Deletion in progress, delaying image set.');
            return;
        }
        const image = new Image();
        image.crossOrigin = "anonymous";
        image.src = imageSrc;
        const canvas = document.createElement('canvas');
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        await new Promise((resolve) => {
            image.onload = resolve;
        });

        ctx.drawImage(
            image,
            crop.x,
            crop.y,
            crop.width,
            crop.height,
            0,
            0,
            crop.width,
            crop.height
        );

        const croppedDataURL = canvas.toDataURL('image/jpeg');

        // Convert Data URL to Blob
        const blob = dataURLtoBlob(croppedDataURL);

        // Define storage path using selection's unique ID.
        const storage = getStorage(app);
        console.log('setting selection images at: ', storagePath)
        const storagePath1 = `${storagePath}/Selections/Images/${selId}.jpeg`;
        console.log('running asf')
        const storageRef = ref(storage, storagePath1);

        // Upload to Firebase Storage
        await uploadBytes(storageRef, blob);

        // Return the download URL
        return await getDownloadURL(storageRef);
    }

    function dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    const handleImageLoad = (index) => {
        setLoadedImages(prev => {
            const updatedLoadedImages = [...new Set([...prev, index])];
            console.log("Loaded images indices:", updatedLoadedImages);
            return updatedLoadedImages;
        });
    };

    const fetchNumberOfImages = async () => {
        if (!userId || !uniqueComponentId) return;

        const storage = getStorage(app);
        const imagesRef = ref(storage, `UserUploads/${userId}/${uniqueComponentId}/Selections/Images`);

        try {
            const result = await listAll(imagesRef);
            numberOfImages.current = result.items.length; // Update the state with the number of images
            console.log(result.items.length);
        } catch (error) {
            console.error('Error fetching images:', error);
            // Handle the error appropriately
        }
    };


    const handleUseTokens = async () => {
        if (isDeductingTokens) return; // Prevent further execution if already processing

        setIsDeductingTokens(true); // Start token deduction process

        await fetchNumberOfImages(); // Ensure the latest count is fetched
        if (numTokens < numberOfImages.current) {
            setHasSufficientTokens(false);
            setIsDeductingTokens(false); // Reset loading state
        } else {
            setHasSufficientTokens(true);
        }

        if (!hasSufficientTokens) {
            navigate('/tokens');
            setIsDeductingTokens(false); // Reset loading state
        } else {
            const functions = getFunctions(app);
            const deductTokensFunc = httpsCallable(functions, 'deductTokens');
            try {
                const response = await deductTokensFunc({ uniqueComponentId });
                setNumTokens(prev => prev - response.data.numberOfImagesDeducted);
                navigate(`/ProblemsSolved/${uniqueComponentId}`);
            } catch (error) {
                console.error('Error deducting tokens:', error);
                // Handle the error
            } finally {
                setIsDeductingTokens(false); // Reset loading state regardless of success or failure
            }
        }
    };


    const handlePayPerProblem = async () => {
        await fetchNumberOfImages();
        const numberOfProblems = numberOfImages.current; // Number of problems the user wants to solve
        const body = JSON.stringify({ numberOfProblems, userId, uniqueComponentId });
        try {
            const response = await fetch('https://us-central1-quick-solve-ai.cloudfunctions.net/createDynamicCheckoutSession', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }

            const data = await response.json();
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({ sessionId: data.id });

            if (error) {
                console.error('Error during checkout:', error);
            }
        } catch (error) {
            console.error('Error during checkout:', error);
        }
    }


    const showLoginOverlay = () => {
        setShowLogin(true);
        setShowSignUp(false);
    };


    const hideLoginOverlay = () => {
        setShowLogin(false);
    };

    const showSignUpOverlay = () => {
        setShowSignUp(true);
        setShowLogin(false);
    };

    const hideSignUpOverlay = () => {
        setShowSignUp(false);
    };

    const migrateAnonymousDataToUser = async (userId, sessionId) => {
        try {
            console.log("Migration started");

            // Generate a unique identifier for the session
            const unique = uuidv4();
            console.log(`Unique ID generated: ${unique}`);

            const storage = getStorage(app);
            const firestore = getFirestore(app);

            // First, download selections.json from anonymous storage
            const selectionsFileRef = ref(storage, `AnonymousUploads/${sessionId}/Selections/selections.json`);
            const selectionsFileUrl = await getDownloadURL(selectionsFileRef);
            const selectionsResponse = await fetch(selectionsFileUrl);
            const selectionsData = await selectionsResponse.json();
            console.log("Selections.json downloaded");

            // Define the root references for anonymous and user data
            const anonymousRootRef = ref(storage, `AnonymousUploads/${sessionId}`);
            const userRootRef = ref(storage, `UserUploads/${userId}/${unique}`);

            // Recursive function to copy data from source to destination
            const copyData = async (sourceRef, destinationRef) => {
                const { items, prefixes } = await listAll(sourceRef);
                console.log("Items and prefixes fetched");

                for (const item of items) {
                    console.log(`Processing item: ${item.name}`);
                    const fileUrl = await getDownloadURL(item);
                    const response = await fetch(fileUrl);
                    const blob = await response.blob();
                    const destinationFileRef = ref(storage, `${destinationRef.fullPath}/${item.name}`);
                    await uploadBytes(destinationFileRef, blob);
                    await deleteObject(item);  // Optionally, delete the source file
                }
                console.log("All items copied");

                for (const prefix of prefixes) {
                    console.log(`Processing prefix: ${prefix.name}`);
                    const destinationSubdirRef = ref(storage, `${destinationRef.fullPath}/${prefix.name}`);
                    await copyData(prefix, destinationSubdirRef);
                }
                console.log("All prefixes copied");
            };

            // Start copying data
            await copyData(anonymousRootRef, userRootRef);
            console.log("Data copying finished");

            // Update imageURL in each selection object
            const updatedSelections = selectionsData.map(sel => {
                const oldUrl = sel.imageURL;
                const newPath = `UserUploads%2F${userId}%2F${unique}`;
                const newUrl = oldUrl.replace(`AnonymousUploads%2F${sessionId}`, newPath);
                return { ...sel, imageURL: newUrl };
            });

            console.log("Selections updated");

            // Create Blob from updated selections and upload to user storage
            const blob = new Blob([JSON.stringify(updatedSelections)], { type: "application/json" });
            const userSelectionsFileRef = ref(storage, `UserUploads/${userId}/${unique}/Selections/selections.json`);
            await uploadBytes(userSelectionsFileRef, blob);

            console.log("Updated selections.json uploaded");

            // Retrieve and save Firestore data
            const anonymousFirestoreRef = doc(firestore, 'anonymous', sessionId);
            const anonymousFirestoreSnap = await getDoc(anonymousFirestoreRef);
            const anonymousData = anonymousFirestoreSnap.data();

            if (anonymousData && Object.keys(anonymousData).length > 0) {
                const userFirestoreRef = doc(firestore, 'users', userId, 'Drafts', unique);
                const creationDate = new Date().toISOString();
                await setDoc(userFirestoreRef, { ...anonymousData, creationDate });
                console.log('Firestore data saved');
            } else {
                console.log("Anonymous data is empty, skipping document creation.");
            }

            // Optionally, delete anonymous Firestore document
            await deleteDoc(anonymousFirestoreRef);
            console.log('Data migration successful');
        } catch (error) {
            console.error('Data migration failed:', error);
        }
    };



    return (
        <div className="main-container">
            <div className={`problem-selection-container ${isCartOpen ? 'shrunk' : ''}`}>
                <div className="header-container">

                    <div className="back-and-title-container">
                        <button onClick={handleBackClick} className="back-button">
                            <ArrowBackIosNewIcon style={{ color: 'white' }} fontSize="medium" />
                        </button>
                        <div className="header-text">Problem Selection</div>
                    </div>

                    {/* Middle buttons */}
                    <div className="middle-buttons-container">
                        <div className="button-container">
                            <button
                                ref={buttonRef}
                                className={`button-select ${selectedButton === 'none' ? 'selected' : ''}`}
                                onClick={() => {
                                    if (selectedButton === 'none') {
                                        setSelectedButton(null);
                                        setIsCreating(false);  // stop the selection mode
                                    } else {
                                        setSelectedButton('none');
                                        setIsCreating(true);   // start the selection mode
                                        setCursor('crosshair');  // change cursor to crosshair
                                    }
                                }}
                            >
                                <HighlightAltSharpIcon style={{ color: 'white' }} fontSize="large" />
                            </button>
                            <div className="button-label">Drag Select</div>
                        </div>

                        <div className="button-container">
                            <button
                                className={`button-select ${selectedButton === 'all' ? 'selected' : ''}`}
                                onClick={() => {
                                    if (selectedButton === 'all') {
                                        setSelectedButton(null);
                                    } else {
                                        setSelectedButton('all');
                                    }
                                }}
                            >
                                <SelectAllSharpIcon style={{ color: 'white' }} fontSize="large" />
                            </button>
                            <div className="button-label">Select Image</div>
                        </div>

                    </div>


                    {!isCartOpen && (
                        <div className="right-button-container">
                            <button
                                className={`shopping-cart-button ${isCartOpen ? 'selected' : ''}`}
                                onClick={() => setIsCartOpen(!isCartOpen)}>
                                <ShoppingCartIcon style={{ color: 'white' }} fontSize="large" />
                            </button>
                            {/* Notification bubble */}
                            {selections.length > 0 && (
                                <div className="notification-bubble">
                                    <span className="notification-text">{selections.length}</span>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div
                    className={`cancel-popup ${showCancelPopup ? '' : 'fade-out'}`}
                    style={showCancelPopup ? { display: 'block' } : { display: 'none' }}
                >
                    <div className="cancel-icon">❌</div> {/* Big ❌ icon */}
                    Canceled
                </div>
                <div
                    className={`not-big-enough-popup ${showNotBigEnoughPopup ? '' : 'fade-out'}`}
                    style={showNotBigEnoughPopup ? { display: 'block' } : { display: 'none' }}
                >
                    <div className="cancel-icon">🔎🍆</div> {/* Big emoji */}
                    Not big enough
                </div>




                <div className="carousel-container">
                    <div className="carousel-items noselect"
                        ref={containerRef}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onMouseMove={handleMouseMove}
                        style={{ cursor: cursor }}
                    >
                        {fileURLs.map((item, index) => (
                            <div key={item} className={`carousel-item ${index === currentFocus ? 'focused' : index < currentFocus ? 'prev' : 'next'}`}>
                                <img
                                    ref={(el) => { imageRefs.current[index] = el; }}
                                    src={item}
                                    alt="Carousel item"
                                    onDragStart={preventDragHandler}
                                    onLoad={(e) => {
                                        handleImageLoad(index)
                                        const img = e.target;
                                        resizeContainer(img);
                                    }}
                                    onError={() => console.log(`Error loading image at index ${index}.`)}
                                    onMouseOver={() => {
                                        if (isCreating) {
                                            setCursor('crosshair'); // set cursor to crosshair only when selection mode is active
                                        }
                                    }} // set cursor to crosshair when mouse is over image
                                    onMouseOut={() => {
                                        if (isCreating) {
                                            setCursor('not-allowed'); // set cursor to crosshair only when selection mode is active
                                        }
                                    }} // set cursor to "not-allowed" when mouse is out of image
                                />
                                {index === currentFocus && selections
                                    .filter(sel => sel.imageIndex === currentFocus)
                                    .map((sel, idx) => {
                                        const currentImage = imageRefs.current[currentFocus];
                                        if (!currentImage) return null;
                                        const imgWidth = currentImage.offsetWidth;
                                        const imgHeight = currentImage.offsetHeight;

                                        if (!sel.ratio) return null;

                                        const actualPosition = {
                                            x: sel.ratio.x * imgWidth,
                                            y: sel.ratio.y * imgHeight
                                        };

                                        const actualSize = {
                                            width: sel.ratio.width * imgWidth,
                                            height: sel.ratio.height * imgHeight
                                        };


                                        const enableResizing = isCreating ? {
                                            top: false,
                                            right: false,
                                            bottom: false,
                                            left: false,
                                            topRight: false,
                                            bottomRight: false,
                                            bottomLeft: false,
                                            topLeft: false
                                        } : {
                                            top: true,
                                            right: true,
                                            bottom: true,
                                            left: true,
                                            topRight: true,
                                            bottomRight: true,
                                            bottomLeft: true,
                                            topLeft: true
                                        };

                                        return (
                                            <Rnd
                                                ref={rndRef}
                                                bounds="parent"
                                                disableDragging={isCreating} 
                                                key={`${sel.id}-${actualPosition.x}-${actualPosition.y}-${actualSize.width}-${actualSize.height}`}
                                                size={actualSize}
                                                position={actualPosition}
                                                onDragStop={(e, d) => {
                                                    const newRatio = {
                                                        x: d.x / imgWidth,
                                                        y: d.y / imgHeight,
                                                        width: sel.ratio.width,
                                                        height: sel.ratio.height
                                                    };

                                                    // If dragged out of the top boundary
                                                    if (d.y < 0) {
                                                        newRatio.y = 0;
                                                        console.log("Dragged out of the top boundary.");
                                                    }

                                                    // If dragged out of the left boundary
                                                    if (d.x < 0) {
                                                        newRatio.x = 0;
                                                        console.log("Dragged out of the left boundary.");
                                                    }

                                                    console.log("Drag Direction:", d); // Logging the drag direction

                                                    // Update the ratio in the state and clear the imageURL
                                                    setSelections(prev => {
                                                        const updated = [...prev];
                                                        const targetSelection = updated.find(s => s.id === sel.id);
                                                        if (targetSelection) {
                                                            targetSelection.ratio = newRatio;
                                                            targetSelection.imageURL = null;  // Remove the existing imageURL
                                                            console.log(`imageURL for selection id ${sel.id} set to null`);
                                                        }
                                                        return updated;
                                                    });

                                                    const updatedSelection = {
                                                        ...sel,
                                                        position: { x: d.x, y: d.y },
                                                        size: { width: newRatio.width * imgWidth, height: newRatio.height * imgHeight }
                                                    };

                                                    console.log("New Selection for cropping:", updatedSelection);

                                                    // Generate and upload the new cropped image
                                                    generateAndUploadCroppedImage(updatedSelection);
                                                }}


                                                onResize={(e, direction, ref, delta, position) => {
                                                    // Check if resizing would make the size smaller than minimum size
                                                    if (ref.offsetWidth < MIN_WIDTH || ref.offsetHeight < MIN_HEIGHT) {
                                                        if (ref.offsetWidth < MIN_WIDTH) {
                                                            ref.style.width = `${MIN_WIDTH}px`;
                                                        }
                                                        if (ref.offsetHeight < MIN_HEIGHT) {
                                                            ref.style.height = `${MIN_HEIGHT}px`;
                                                        }
                                                        // Since we're enforcing the size, we can return early to prevent further resizing in this event
                                                        return;
                                                    }
                                                }}

                                                onResizeStop={(e, direction, ref, delta, position) => {
                                                    const newWidth = ref.offsetWidth;
                                                    const newHeight = ref.offsetHeight;

                                                    let adjustedWidth = newWidth;
                                                    let adjustedHeight = newHeight;
                                                    let adjustedX = position.x;
                                                    let adjustedY = position.y;

                                                    console.log("Resize Direction:", direction);  // Logging the resize direction

                                                    // Note: The checks for enforcing the minimum size during resizing have been commented out 
                                                    // as they are handled in the onResize event handler.

                                                    const newRatio = {
                                                        x: adjustedX / imgWidth,
                                                        y: adjustedY / imgHeight,
                                                        width: adjustedWidth / imgWidth,
                                                        height: adjustedHeight / imgHeight
                                                    };

                                                    // Log new position and size (after resizing)
                                                    console.log("New Position (after resizing):", { x: adjustedX, y: adjustedY });
                                                    console.log("New Size (after resizing):", { width: adjustedWidth, height: adjustedHeight });
                                                    console.log("actual position:", { x: actualPosition.x, y: actualPosition.y })

                                                    // Update the ratio in the state and clear the imageURL
                                                    setSelections(prev => {
                                                        const updated = [...prev];
                                                        const targetSelection = updated.find(s => s.id === sel.id);
                                                        if (targetSelection) {
                                                            targetSelection.ratio = newRatio;
                                                            targetSelection.imageURL = null;  // Remove the existing imageURL
                                                            console.log(`imageURL for selection id ${sel.id} set to null`);
                                                        }
                                                        return updated;
                                                    });

                                                    const updatedSelection = {
                                                        ...sel,
                                                        position: { x: adjustedX, y: adjustedY },
                                                        size: { width: newRatio.width * imgWidth, height: newRatio.height * imgHeight }
                                                    };

                                                    console.log("New Selection for cropping:", updatedSelection);

                                                    // Generate and upload the new cropped image
                                                    generateAndUploadCroppedImage(updatedSelection);
                                                }}

                                                enableResizing={enableResizing}


                                                style={{ backgroundColor: 'rgba(128, 0, 128, 0.2)', border: '2px solid purple' }}
                                            >
                                                <div className="selection-label">
                                                    Problem #{sel.problemNumber}
                                                </div>


                                                <div
                                                    className="remove-selection-btn"
                                                    onClick={(e) => {
                                                        e.stopPropagation();  // Prevents the Rnd component from triggering drag or other events
                                                        handleRemoveSelection(sel.id);
                                                    }}
                                                >
                                                    ×
                                                </div>
                                            </Rnd>
                                        )
                                    }
                                    )}

                                {currentSelection && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            backgroundColor: 'purple',
                                            opacity: 0.6,
                                            left: currentSelection.position.left,    // Use left
                                            top: currentSelection.position.top,      // Use top
                                            ...currentSelection.size
                                        }}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="carousel-controls">
                        <div className="carousel-button" onClick={() => setCurrentFocus(prev => Math.max(prev - 1, 0))}>
                            <span>&lt;</span>
                        </div>
                        <div className="carousel-index">
                            <span>{currentFocus + 1} / {fileURLs.length}</span>
                        </div>
                        <div className="carousel-button" onClick={() => setCurrentFocus(prev => Math.min(prev + 1, fileURLs.length - 1))}>
                            <span>&gt;</span>
                        </div>
                    </div>
                </div>

            </div>
            {isCartOpen && (
                <div className="cart-container">
                    <div className="cart-header">
                        <div className="right-button-container">
                            <button
                                className={`cart-close-btn ${isCartOpen ? 'selected' : ''}`}
                                onClick={() => setIsCartOpen(!isCartOpen)}>
                                <span style={{ marginRight: '-3px', fontSize: '20px', fontWeight: '800', color: 'white' }}>&lt;</span>
                                <ShoppingCartIcon style={{ color: 'white', fontSize: '40px' }} fontSize="inherit" />
                            </button>
                        </div>
                        <div className="cart-title">Your Cart</div>
                        <div className="cart-items-count">{selections.length}</div> {/* Dynamically update this count */}
                    </div>


                    <ul className="cart-list">
                        {selections.length > 0 ? (
                            <>
                                {selections
                                    .sort((a, b) => a.problemNumber - b.problemNumber)
                                    .map((sel, index) => (
                                        <li key={sel.id} className="cart-item">
                                            <div className="cart-item-image">
                                                {sel.imageURL ? (
                                                    <img
                                                        src={sel.imageURL}
                                                        alt={`Cropped from page ${sel.imageIndex + 1}`}
                                                    />
                                                ) : (
                                                    <div className="cart-item-image-spinner">
                                                    <l-dot-spinner size="40" speed="0.9" color="black"></l-dot-spinner>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="cart-item-details">
                                                <div className="problem-info">
                                                    <div className="problem-number">Problem #{sel.problemNumber}</div>
                                                    <div className="problem-page">Page {sel.imageIndex + 1}</div>

                                                </div>
                                                {!isAnonymous && (
                                                    <div className="problem-cost">
                                                        <div className="price">$0.25</div> or 1 token
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                className="cart-item-remove"
                                                onClick={(e) => {
                                                    e.stopPropagation();  // Prevents any parent handlers from being executed
                                                    handleRemoveSelection(sel.id);
                                                }}
                                            >
                                                ×
                                            </div>
                                        </li>
                                    ))
                                }
                                {!isAnonymous && (
                                    <li className="cart-item service-fee-item">
                                        <div className="service-fee-details">
                                            <span className="service-fee-label">Service Fee:</span>
                                            <span className="service-fee-amount">$0.74 or FREE</span> using tokens
                                        </div>
                                    </li>
                                )}
                            </>
                        ) : (
                            <div className="cart-empty">
                                <span className="emoji" role="img" aria-label="Sad Face">😞</span>
                                <p>
                                    <span className="sentence-1">You have no problems in your cart right now.</span>
                                    <br /><br />
                                    <span className="sentence-2">Use the selection tool and drag a selection onto your image!</span>
                                </p>
                            </div>

                        )}
                    </ul>
                    {selections.length > 0 && (
                        <div className="cart-footer">
                            {isAnonymous ? (
                                <button className="anonymous-btn" onClick={showLoginOverlay}>
                                    <div className="anonymous-message">Log in to solve your problems!</div>
                                    <div className="anonymous-subtext">Let's get it done.</div>
                                </button>
                            ) : (
                                <div className="pay-button-container">
                                    <button className="pay-per-problem-btn disabled"
                                        onClick={handlePayPerProblem}
                                        disabled={true}>
                                        <div>Pay per problem</div>
                                        <div className="subtotal-container">
                                            <div className="subtotal-amount">
                                                ${(selections.length * 0.25 + 0.74).toFixed(2)}
                                            </div>
                                            <div className="subtotal-text">(subtotal)</div>
                                        </div>
                                    </button>
                                    <div className="or-text"> or </div>
                                    <button
                                        className="use-tokens-btn"
                                        onClick={handleUseTokens}
                                        disabled={isDeductingTokens} // Disable button when token deduction is in progress
                                    >
                                        {isDeductingTokens ? (
                                            // Show loading animation when tokens are being deducted
                                            <l-spiral size="30" speed="0.9" color="white" />
                                        ) : hasSufficientTokens ? (
                                            <>Use {selections.length} Token{selections.length !== 1 && 's'}</>
                                        ) : (
                                            <div>
                                                <div style={{ fontWeight: 800, fontSize: '14px' }}>Current balance: {numTokens} 😢</div>
                                            </div>
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
            {showLogin && (
                <div className="overlay">
                    <Login onClose={hideLoginOverlay} onSignUpClick={showSignUpOverlay} />
                </div>
            )}
            {showSignUp && (
                <div className="overlay">
                    <SignUp onClose={hideSignUpOverlay} onLoginClick={showLoginOverlay} />
                </div>
            )}

        </div>
    );
};

export default ProblemSelection;