import React, { useState } from 'react';
import './EmailGenerator.css';

function EmailGenerator() {
    const [personName, setPersonName] = useState('');
    const [yourName, setYourName] = useState('');
    const [yourPosition, setYourPosition] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [recipientEmail, setRecipientEmail] = useState('');
    const [generatedLink, setGeneratedLink] = useState('');

    const generateLink = () => {
        const subject = encodeURIComponent("Recruiting Opportunity with the Texas Hispanic Business Student Association");
        const body = encodeURIComponent([
            `Dear ${personName},`,
            ``,
            `My name is ${yourName} and I am the ${yourPosition} with the Hispanic Business Student Association at The University of Texas at Austin. I would like to personally extend an invitation for ${companyName} to attend our 44th Annual Company Night event taking place on September 17th, 2024.`,
            ``,
            `Company Night is UT’s largest student-run career fair and was named the 2021 McCombs School of Business’ Most Impactful Event. HBSA was also named a finalist for the 2022 University of Texas Swing Out Awards for the Best Professional Organization and was recognized as the Most Outstanding Organization at the McCombs School of Business in 2022 and 2023. The goal of Company Night is to bring together top students of different backgrounds and allow them to discover opportunities with various companies. Company Night is conveniently held the night before the McCombs Career Expo to give students and recruiters the opportunity to foster more meaningful connections. The environment of Company Night is meant to be intimate and professional, where students can network and have more one-on-one interaction with recruiters the night before Career Expo.`,
            ``,
            `It would be an honor and a great opportunity for students if ${companyName} attended Company Night. Students at the University of Texas have so much to offer and ${companyName} would also benefit greatly from attending this event.`,
            ``,
            `If you are interested in attending our event, please let me know so the Company Night Sponsoring Officer, Kimberly Sarabia, can send you the Company Night Information Packet. The packet contains detailed information about the event. For reference, I have also attached an infographic that provides attendance statistics from last year’s event.`,
            ``,
            `Please feel free to contact me if you have any questions. Thank you for your time, and I look forward to hearing back from you soon.`,
            ``,
            `Kind Regards,`,
            `${yourName}`
        ].join('\n'));

        const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${recipientEmail}&su=${subject}&body=${body}`;
        setGeneratedLink(mailtoLink);
    };

    return (
      <div className="EmailGenerator">
          <h1>Email Link Generator</h1>
          <div className="EmailGenerator-div">
              <label htmlFor="personName" className="EmailGenerator-label">Person's Name</label>
              <input id="personName" type="text" placeholder="Person's Name" value={personName} onChange={e => setPersonName(e.target.value)} className="EmailGenerator-input" />
          </div>
          <div className="EmailGenerator-div">
              <label htmlFor="yourName" className="EmailGenerator-label">Your Name</label>
              <input id="yourName" type="text" placeholder="Your Name" value={yourName} onChange={e => setYourName(e.target.value)} className="EmailGenerator-input" />
          </div>
          <div className="EmailGenerator-div">
              <label htmlFor="yourPosition" className="EmailGenerator-label">Your Position</label>
              <input id="yourPosition" type="text" placeholder="Your Position" value={yourPosition} onChange={e => setYourPosition(e.target.value)} className="EmailGenerator-input" />
          </div>
          <div className="EmailGenerator-div">
              <label htmlFor="companyName" className="EmailGenerator-label">Company Name</label>
              <input id="companyName" type="text" placeholder="Company Name" value={companyName} onChange={e => setCompanyName(e.target.value)} className="EmailGenerator-input" />
          </div>
          <div className="EmailGenerator-div">
              <label htmlFor="recipientEmail" className="EmailGenerator-label">Recipient's Email</label>
              <input id="recipientEmail" type="email" placeholder="Recipient's Email" value={recipientEmail} onChange={e => setRecipientEmail(e.target.value)} className="EmailGenerator-input" />
          </div>
          <button onClick={generateLink} className="EmailGenerator-button">Generate Link</button>
          {generatedLink && <p><a href={generatedLink} target="_blank" rel="noopener noreferrer" className="EmailGenerator-link">Send Email</a></p>}
      </div>
  );
}

export default EmailGenerator;