import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NoAccessPage = ({ isPromptActive }) => {
    const [countdown, setCountdown] = useState(3);
    const navigate = useNavigate();

    useEffect(() => {
        if (isPromptActive) {
            const timer = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown <= 1) {
                        clearInterval(timer);
                        navigate('/');
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [isPromptActive, navigate]);

    return (
        <div style={{
            width: '100%',
            height: '100vh', // 100% of the viewport height
            position: 'fixed', // Fixed position
            top: 0, left: 0, // Starting at the top-left corner
            background: 'black',
            display: 'flex', // Using Flexbox
            flexDirection: 'column', // Align children vertically
            justifyContent: 'center', // Center horizontally
            alignItems: 'center', // Center vertically
            textAlign: 'center' // Center text
        }}>
            <div style={{
    color: 'white',
    fontSize: 60,
    fontFamily: 'Montserrat',
    wordWrap: 'break-word',
    marginBottom: isPromptActive ? '20px' : '0',
}}>
    <span style={{ fontWeight: 900, fontStyle: 'italic' }}>Quicksolve</span>
    <span>.ai</span>
</div>

            {isPromptActive && (
                <div>
                    <div style={{ color: 'white', fontSize: '24px', fontFamily: 'Montserrat', paddingTop: '20px' }}>
                        Sorry, this webpage doesn't exist.
                    </div>
                    <div style={{ color: 'white', fontSize: '20px', fontFamily: 'Montserrat', paddingTop: '10px' }}>
                        Navigating you home in {countdown}...
                    </div>
                </div>
            )}
        </div>
    );
};

export default NoAccessPage;
