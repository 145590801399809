import React, { useState, useEffect } from 'react';
import './Cards.css';
import Dime from '../assets/dime.png';
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import Iop from '../assets/imagesorpdfs.png';
import ProbSel from '../assets/probsel.png'
import Cart from '../assets/cart.png'
import SolvedSel from '../assets/solvedmock.png'



const Cards = () => {
  const [selectedButton, setSelectedButton] = useState('Accuracy');

  // Function to handle button click
  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  // Step content
  const step1Content = (
    <div className="step-content">
      <div>Upload pdfs and/or pictures</div>
      <img src={Iop} alt="Step 1" />
    </div>
  );

  const step2Content = (
    <div className="step-content">
      <div>Define Problem region{`(`}s{`)`}</div>
      <img src={ProbSel} alt="Step 2" />
    </div>
  );

  const step3Content = (
    <div className="step-content">
      <div>Checkout and Purchase</div>
      <img src={Cart} alt="Step 3" />
    </div>
  );

  const step4Content = (
    <div className="step-content">
      <div>Receive your solutions</div>
      <img src={SolvedSel} alt="Step 4" />
    </div>
  );
  

  // Step validators (optional)
  function step2Validator() {
    // logic to validate step
    return true; // return true or false
  }

  function step3Validator() {
    // logic to validate step
    return true; // return true or false
  }

  function onFormSubmit() {
    // logic for form submission
  }



  return (
    <div className="cards-container1">
      <div className="card-container">
        <h2 className="card-title">WHAT DO WE OFFER?</h2>
        <div className="card-content1">
          <span className="text-bold">Comprehension</span>
          <span className="text-medium">. </span>
          <span className="text-regular">We’re in the business of making students' lives easier. Homework can be daunting, and getting problem-specific help is often hard to find. Using industry-leading Artificial Intelligence, </span>
          <span className="text-bold">we show you the steps to fully understanding YOUR assignment's problems in a comprehensive manner.</span>
          <span className="text-regular"> We make understanding your homework a breeze.<br /></span>
          <br />
          <span className="text-regular">Whether your assignment is </span>
          <span className="text-bold">multiple choice or open-ended</span>
          <span className="text-medium">, </span>
          <span className="text-regular">we got you covered</span>
          <span className="text-medium">.</span>
        </div>
      </div>

      <div className="card-container">
        <h2 className="card-title">WHY US?</h2>
        <div className="card-content2">
          <div className="buttons-row">
            <button className={`button gray-button ${selectedButton === 'Accuracy' ? 'selected' : ''}`} onClick={() => handleButtonClick('Accuracy')}>Accuracy</button>
            <button className={`button gray-button ${selectedButton === 'Cost' ? 'selected' : ''}`} onClick={() => handleButtonClick('Cost')}>Cost</button>
            <button className={`button gray-button ${selectedButton === 'Data' ? 'selected' : ''}`} onClick={() => handleButtonClick('Data')}>User Data</button>
          </div>

          {selectedButton === 'Accuracy' && (
            <div className='accaf'>
              <div>
                <div className="card-text">
                  <p>Don’t underestimate sophisticated Artificial Intelligence.</p>
                  <p>We recommend using <b>QuickSolve</b> for Biology, Mathematics, Chemistry, and History (before 2024). <b>QuickSolve</b> uses artificial intelligence for a user experience that is both comprehensible and equation-savvy.</p>
                </div>

                <div className="card-text">
                  <p>We acknowledge that when it comes to harder Maths/Topics like Calculus 3, the accuracy of QuickSolve's outputted solutions are not always 100%. That being said though, it should be noted that the steps in the solutions QuickSolve outputs are always enough to guide you to getting the right solution. AND!! If you're unsure about our approach to your problem, we let you <b>DOUBLE CHECK</b> it! Have AI walk through your problem twice.</p>
                </div>
              </div>
            </div>
          )}

          {selectedButton === 'Cost' && (
            <div className="cost-content">
              <div className="cost-row">
                <p>Instant help at a <span className="text-bold">dirt cheap</span> price. We make access to help, access to Artificial Intelligence easy and <span className="text-bold">cost-affordable</span>, because we’re broke college students ourselves, and understand the struggle. <span className="text-bold">Cents on the dollar. AND!! We let you double check, YOU GET TO HAVE AI SOLVE YOUR PROBLEM TWICE.</span></p>
              </div>
              <div className="cost-columns">
                <div className="dime-column">
                  <img src={Dime} alt="Dime" className="dime-image" />
                </div>
                <div className="text-column">
                  <p className="lonesome-dime">A SINGLE DIME (10¢)</p>
                  <div className='sub-text'>Per Problem</div>
                  <p>A <span className="text-bold">step by step guide to understanding your Homework</span> cheaper than a pack of gum. 20 solutions at a low low price of ≈ $1.99. (using tokens)</p>
                </div>
              </div>
            </div>
          )}

          {selectedButton === 'Data' && (
            <div className='dataaf'>
              <div>
                <span className="text-bold">We’re not like other companies</span>
                <span className="text-medium"> in the homework space, we don’t shell out user data. That’s not cool </span>
                <span className="text-medium"> and we’re students ourselves, we understand the struggle in trying to learn new and complicated subjects, and created </span>
                <span className="text-bold">QuickSolve</span>
                <span className="text-medium"> for that reason. Your usage is safe with us.</span>
                <br /><br />
                <span className="text-medium">We provide Homework Help 😁, so that you can say goodbye to guesswork, restless nights, unguided study, and unhelpful tutors. Get your work done much much faster. </span>
                <span className="text-regular">Boost your productivity and learn effectively by seeing the steps you need to take to solve your problems.</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="card-container">
        <h2 className="card-title">HOW DOES THIS WORK?</h2>
        <div className="card-content2">
          <StepProgressBar
            startingStep={0}
            primaryBtnClass="custom-primary-btn"
            onSubmit={onFormSubmit}
            steps={[
              {
                label: 'Upload',
                name: 'step 1',
                content: step1Content
              },
              {
                label: 'Select',
                name: 'step 2',
                content: step2Content,
                validator: step2Validator
              },
              {
                label: 'Purchase',
                name: 'step 3',
                content: step3Content,
                validator: step3Validator
              },
              {
                label: 'Receive',
                name: 'step 4',
                content: step4Content
              }
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Cards;
