import React, { useEffect, useState } from 'react';
import LoggedInHeader from './LoggedInHeader';
import './Solved.css';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';

export default function Solved() {
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState('');
  const navigate = useNavigate();
  const [solvedProblems, setSolvedProblems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const savedUsername = sessionStorage.getItem('username');
    if (savedUsername) {
        setUsername(savedUsername);
    }

    const unsubscribe = auth.onAuthStateChanged(async user => {
        if (user) {
            setUserId(user.uid);

            const solvedRef = collection(db, `users/${user.uid}/Solved`);
            const querySnapshot = await getDocs(solvedRef);
            const problems = querySnapshot.docs.map(doc => {
                const data = doc.data();
                const solvedOnDate = data.solvedOn ? data.solvedOn.toDate() : new Date();
                return {
                    id: doc.id,
                    solvedOnDate,
                    ...data
                };
            });

            // Sort the problems by date, newest first
            problems.sort((a, b) => b.solvedOnDate - a.solvedOnDate);
            setSolvedProblems(problems);
            setIsLoading(false);
        }
    });

    return () => unsubscribe();
}, []);


  const formatDate = (date) => {
    // Format the date as "January 9th, 2023"
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString("en-US", options);
  };

  const formatTime = (date) => {
    // Format the time as "7:19 PM"
    const options = { hour: 'numeric', minute: '2-digit', hour12: true };
    return date.toLocaleTimeString("en-US", options);
  };

  return (
    <div className="loggedInHomePage">
      <LoggedInHeader name={username} />
      <div className="solved-container">
      {isLoading ? (
          // Loading animation
          <div className="loading-container"> 
              <l-dot-pulse
                  size="60"
                  speed="1.8"
                  color="#8A67F3"
              ></l-dot-pulse>
          </div>
        ) : solvedProblems.length === 0 ? (
          <div className="no-solved-container">
        <div className="no-solved-message">
          You don't have any solved problems yet 😭! <br /> Upload your documents/pics!
        </div>
      </div>
        ) : ( 
        solvedProblems.map(problemSet => (
          <div className="solved-item" onClick={() => navigate(`/problemssolved/${problemSet.id}`)}>
          <div className="solved-item-col1">
            <div className="solved-item-date">{formatDate(problemSet.solvedOnDate)}</div>
            <div className="solved-item-time">{formatTime(problemSet.solvedOnDate)}</div>
            <div className="solved-item-tokens">- {problemSet.tokensCost} tokens used</div>
          </div>
          <div className="solved-item-col2">
            <img className="solved-item-image" src={problemSet.imageUrls[0].imageUrl} alt="Problem" />
          </div>
        </div>        
        ))
        )} 
      </div>
    </div>
  );
}
