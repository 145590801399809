import React, { useState, useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import { InlineMath, BlockMath } from 'react-katex';
import { getFunctions, httpsCallable } from "firebase/functions";
import 'katex/dist/katex.min.css';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import { app, auth } from '../firebaseConfig'; // Adjust this import based on your Firebase configuration
import './ProblemsSolved.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HomeIcon from '@mui/icons-material/Home';
import Info from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';
import { quantum } from 'ldrs'
import { dotPulse } from 'ldrs'
import { getFirestore, doc, getDoc, onSnapshot } from 'firebase/firestore';
import NoAccessPage from '../pages/NoAccessPage';
import doneImage from '../assets/done.png';
import unsureImage from '../assets/unsure.png';
import { lineSpinner } from 'ldrs'

lineSpinner.register()
quantum.register()
dotPulse.register()


const ProblemsSolved = () => {
    const [isTranscriptionExpanded, setIsTranscriptionExpanded] = useState(true);
    const [isSolutionExpanded, setIsSolutionExpanded] = useState(true);
    const [showAddedInfo, setShowAddedInfo] = useState(false);
    const [currentProblemIndex, setCurrentProblemIndex] = useState(0);

    const navigate = useNavigate();
    const [imageUrls, setImageUrls] = useState([]);
    const [latexDataArray, setLatexDataArray] = useState([]);
    const [confidenceArray, setConfidenceArray] = useState([]);
    const [currentSolutionIndex, setCurrentSolutionIndex] = useState(0)
    const [gpt4ResponseArray, setGpt4ResponseArray] = useState([]);
    const { uniqueComponentId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [accessDenied, setAccessDenied] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState({});
    const [solvedData, setSolvedData] = useState(null); // Add state for storing the solved data
    const [retryAttemptedStatus, setRetryAttemptedStatus] = useState([]);
    const [retryStatus, setRetryStatus] = useState({ inProgress: false, completed: false });


    useEffect(() => {
        setCurrentSolutionIndex(0); // Reset solution index when the problem changes
    }, [currentProblemIndex]);

    useEffect(() => {
        if (Array.isArray(gpt4ResponseArray[currentProblemIndex]) && gpt4ResponseArray[currentProblemIndex].length > 1) {
            setRetryStatus({ inProgress: false, completed: true });
        }
        // have to figure out how to disable retry button before first output is given
        // i need to have the button not appear until the first index of the chatgpt4response array is available
    }, [gpt4ResponseArray, currentProblemIndex]); 

    
    const handleSolveAgain = async (uniqueComponentId, currentProblemIndex) => {
        setIsButtonDisabled(prevState => ({ ...prevState, [currentProblemIndex]: true }));
        setRetryStatus({ ...retryStatus, inProgress: true });
        const functions = getFunctions(app);
        const retryProblemSolution = httpsCallable(functions, "retryProblemSolution");

        retryProblemSolution({
            uniqueComponentId,
            problemIndex: currentProblemIndex,
            userId: auth.currentUser.uid
        })
            .then((result) => {
                console.log(result.data.message);
                // Update component state or UI as needed to reflect the newly recomputed solution
            })
            .catch((error) => {
                console.error("Error when calling retryProblemSolution:", error);
                setRetryStatus({ inProgress: false, completed: true });
            });
    };



    // Function to listen for updates on Firestore document
    const listenForUpdates = () => {
        const db = getFirestore(app);
        const solvedRef = doc(db, `users/${auth.currentUser.uid}/Solved/${uniqueComponentId}`);

        return onSnapshot(solvedRef, (doc) => {
            if (doc.exists()) {
                const data = doc.data();
                setSolvedData(data);
                const imageUrls = Object.values(data.imageUrls || {}).map(img => img.imageUrl);
                setImageUrls(imageUrls);

                const newLatexData = Object.values(data.imageUrls || {}).map(img => img.mathpixOutput || {});
                setLatexDataArray(newLatexData);

                const newConfidenceArray = newLatexData.map(ld => ld.confidence || 0);
                setConfidenceArray(newConfidenceArray);

                const newGpt4ResponseArray = Object.values(data.imageUrls || {}).map(img => img.gpt4Response || []);
                setGpt4ResponseArray(newGpt4ResponseArray);

                const retryStatuses = Object.values(data.imageUrls || {}).map(img => !!img.retryAttempted);
                setRetryAttemptedStatus(retryStatuses);
            } else {
                console.log("No such document!");
            }
        });
    };

    const fetchImageUrls = async () => {
        const user = auth.currentUser;
        if (!uniqueComponentId || !user) return;

        try {
            const storage = getStorage(app);
            const imagesRef = ref(storage, `UserUploads/${user.uid}/${uniqueComponentId}/Selections/Images`);
            const { items } = await listAll(imagesRef);
            const urls = await Promise.all(items.map(item => getDownloadURL(item)));
            setImageUrls(urls);
        } catch (error) {
            console.error('Error fetching image URLs:', error);
        }
    };

    // Watch for authentication status
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            console.log("Auth state changed, user:", currentUser);
            setUser(currentUser);
            if (!currentUser) {
                console.log("User not authenticated, access denied.");
            } else {
                console.log("User authenticated.");
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    // Setup listener for Firestore updates
    useEffect(() => {
        if (user && uniqueComponentId) {
            const unsubscribeFirestore = listenForUpdates();

            return () => unsubscribeFirestore();
        }
    }, [user, uniqueComponentId]);

    // Verify access and fetch images
    useEffect(() => {
        if (user && uniqueComponentId) {
            const verifyAccessAndFetchImages = async () => {
                try {
                    console.log("Verifying access for user:", user.uid, " and uniqueComponentId:", uniqueComponentId);
                    const db = getFirestore(app);
                    const solvedRef = doc(db, `users/${user.uid}/Solved/${uniqueComponentId}`);
                    const docSnap = await getDoc(solvedRef);

                    if (!docSnap.exists()) {
                        console.log("Access denied, uniqueComponentId not found in Solved collection.");
                        setAccessDenied(true);
                        setIsLoading(false);
                    } else {
                        console.log("Access granted, fetching image URLs.");
                        await fetchImageUrls();
                        setIsLoading(false);
                    }
                } catch (error) {
                    console.error('Error in access verification:', error);
                    setAccessDenied(true);
                    setIsLoading(false);
                }
            };

            verifyAccessAndFetchImages();
        }
    }, [user, uniqueComponentId, navigate]);

    const solutionsRef = useRef(null);
    const containerRefs = useRef([]);

    useEffect(() => {
        // Optional: Update the positions when the size of the containers might change
        const updatePositions = () => {
            // Code to update positions based on dynamic heights
        };
        window.addEventListener('resize', updatePositions);
        return () => window.removeEventListener('resize', updatePositions);
    }, []);

    const handleScroll = () => {
        const container = solutionsRef.current;
        if (container) {
            const scrollTop = container.scrollTop;
            let foundIndex = 0;

            containerRefs.current.forEach((ref, index) => {
                if (ref) {
                    const { offsetTop, offsetHeight } = ref;
                    if (scrollTop >= offsetTop && scrollTop < offsetTop + offsetHeight) {
                        foundIndex = index;
                    }
                }
            });

            setCurrentProblemIndex(foundIndex);
        }
    };

    if (isLoading) {
        console.log("Showing loading screen.");
        return <NoAccessPage isPromptActive={false} />;
    }

    if (accessDenied) {
        console.log("Showing no access screen.");
        return <NoAccessPage isPromptActive={true} />;
    }

    const handlePrevClick = () => {
        if (currentProblemIndex > 0) {
            console.log("Previous button clicked. Current index:", currentProblemIndex - 1);
            setCurrentProblemIndex(currentProblemIndex - 1);
        }
    };

    const handleNextClick = () => {
        if (currentProblemIndex < imageUrls.length - 1) {
            console.log("Next button clicked. Current index:", currentProblemIndex + 1);
            setCurrentProblemIndex(currentProblemIndex + 1);
        }
    };

    const updateCurrentProblemIndex = (index) => {
        setCurrentProblemIndex(index);
    };

    const currentLatexData = latexDataArray[currentProblemIndex] || { text: '', html: '', data: [] };
    const currentConfidence = confidenceArray[currentProblemIndex] || 0;
    // need to fetch this to fetch the current gpt4response array for each problem instead of the old single response
    const currentGpt4Response = gpt4ResponseArray[currentProblemIndex] || '';

    const renderLatex = (latex, isInline = true) => {
        const blockStyle = { margin: '10px 0' };
        return isInline ? <InlineMath math={latex} /> : <BlockMath math={latex} style={blockStyle} />;
    };

    const handleLatexTag = (node) => {
        if (node.type === 'tag' && node.name === 'latex') {
            // Check if it's a block or inline based on parent node's class
            const isInline = node.parent && node.parent.attribs && node.parent.attribs.class.includes('math-inline');
            return renderLatex(node.children[0].data, isInline);
        }
    };

    const renderGpt4Response = () => {
        const responses = gpt4ResponseArray[currentProblemIndex] || [];

        // Check if there are any responses for the current problem
        const currentResponse = responses[currentSolutionIndex] ? responses[currentSolutionIndex] : "No response available";

        const blockRegex = /\$\$(.*?)\$\$/gs;
        const inlineRegex = /\\\((.*?)\\\)/gs;

        const splitResponse = currentResponse.split(blockRegex).map((part, index) => {
            if (index % 2 === 0) {
                // This part is not LaTeX
                // Split further for inline LaTeX
                return part.split(inlineRegex).map((subPart, subIndex) => {
                    if (subIndex % 2 === 0) {
                        // This subPart is not LaTeX
                        return subPart;
                    } else {
                        // This subPart is inline LaTeX
                        return <InlineMath math={subPart} />;
                    }
                });
            } else {
                // This part is block LaTeX
                return <BlockMath math={part} />;
            }
        });

        return <>{splitResponse.flat()}</>;
    };

    // Function to navigate to the next solution
    const handleNextSolution = () => {
        setCurrentSolutionIndex(prevIndex => {
            const responses = gpt4ResponseArray[currentProblemIndex] || [];
            return (prevIndex + 1) % responses.length; // Cycle through solutions
        });
    };

    const ProblemStatus = ({ index, status, onClick, currentProblemIndex }) => {
        let icon;
        switch (status) {
            case 'unsure':
                icon = <img src={unsureImage} alt="Unsure" style={{ width: 30, height: 30 }} />; // Size directly on image
                break;
            case 'solved':
                icon = <img src={doneImage} alt="Solved" style={{ width: 30, height: 30 }} />;
                break;
            case 'loading':
                icon = <l-line-spinner size="30" speed="1" stroke="10" style={{ bottom: 10, right: 10 }} color="#8A67F3" ></l-line-spinner>; // Adjust size as needed
                break;
            default:
                icon = null;
        }

        const backgroundColor = index === currentProblemIndex ? '#D6BCFA' : '#EEEEEE'; // Light purple for current index

        return (
            <button
                onClick={onClick}
                style={{
                    width: 60,
                    height: 60,
                    background: backgroundColor,
                    borderRadius: 10,
                    margin: '10px 0',
                    border: 'none',
                    cursor: 'pointer',
                    position: 'relative', // Position children absolutely
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <div style={{
                    color: 'black',
                    fontSize: 30,
                    fontFamily: 'Montserrat',
                    fontWeight: '800',
                    position: 'relative',
                }}>
                    {index + 1}
                    <div style={{
                        position: 'absolute', // Absolute positioning for the icon
                        bottom: -30, // Adjust to position icon outside the number area
                        right: -35, // Adjust as needed
                    }}>
                        {icon}
                    </div>
                </div>
            </button>
        );
    };

    const getProblemStatus = (gpt4ResponseArray) => {
        // Check if the array is not empty and extract the first response
        if (gpt4ResponseArray && gpt4ResponseArray.length > 0) {
            const firstResponse = gpt4ResponseArray[0];

            // Now you can safely use string operations on firstResponse
            const words = firstResponse.replace(/[^\w\s]|_/g, "")
                .replace(/\s+/g, " ")
                .trim()
                .split(' ');

            const lastWord = words.pop().toUpperCase();

            if (lastWord === 'SOLVED') {
                return 'solved';
            } else if (lastWord === 'UNSURE') {
                return 'unsure';
            }
        }
        // If there's no response or it's not in the expected format, default to 'loading'
        return 'loading';
    };



    return (
        <div className="problems-solved-container">
            <header className="problems-solved-header">
                <button className="home-button" onClick={() => { navigate('/') }}>
                    <HomeIcon style={{ color: "#7B41D9", fontSize: '25px' }} />
                </button>
                <span style={{ color: 'white', fontFamily: 'Montserrat', fontSize: '2rem', marginLeft: '100px' }}>
    <span style={{ fontWeight: '900', fontStyle: 'italic' }}>Quicksolve</span>
    <span>.ai</span>
</span>

                {/* <button className="chevron-button" onClick={handlePrevClick}>
                    <ArrowBackIosNewIcon style={{ color: "#7B41D9", fontSize: '20px' }} />
                </button>
                <div className="spacer"></div>
                <div className="problems-solved-header-text">
                    <span className="problem-label">Problem {currentProblemIndex + 1}</span>
                    <span className="of-label"> of </span>
                    <span className="total-number">{imageUrls.length}</span>
                </div>
                <div className="spacer"></div>
                <button className="chevron-button" onClick={handleNextClick}>
                    <ArrowForwardIosIcon style={{ color: "#7B41D9", fontSize: '20px' }} />
                </button> */}

            </header>

            <div className="main-content">
                <div className="problem-status-container">
                    {imageUrls.map((_, index) => (
                        <ProblemStatus
                            key={index}
                            index={index}
                            currentProblemIndex={currentProblemIndex}
                            status={getProblemStatus(gpt4ResponseArray[index])}
                            onClick={() => updateCurrentProblemIndex(index)}
                        />
                    ))}
                </div>
                <div className="content-container">
                    <div className="column-left">
                        <div className="transcription-container">
                            <div className="transcription-header">
                                <div className="transcription-text">Problem {currentProblemIndex + 1}</div>
                                {/* <button className="expand-button" onClick={() => setIsTranscriptionExpanded(!isTranscriptionExpanded)}>
                                <ExpandMoreIcon style={{ fontSize: '40px' }} className={isTranscriptionExpanded ? 'expanded-icon' : 'collapsed-icon'} />
                            </button> */}
                            </div>
                            {isTranscriptionExpanded && (
                                <div className="transcription-content">
                                    <div className="image-container">
                                        <h2>Your Image</h2>
                                        <img src={imageUrls[currentProblemIndex]} alt="Uploaded" />
                                    </div>
                                    <div className="mathpix-output">
                                        <div className="transcription-title-container">
                                            <h2>Our Transcription</h2>
                                            {currentLatexData.text !== '' && (
                                                <button className="info-button" onClick={() => { setShowAddedInfo(!showAddedInfo) }}>
                                                    <Info style={{ fontSize: '25px', color: 'black' }} />
                                                </button>
                                            )}
                                        </div>
                                        {showAddedInfo ? <div className="added-info">
                                            <p>Confidence in Transcription</p>
                                            <div className="confidence-container">
                                                <div className="confidence-bar" style={{ width: `${currentConfidence * 100}%`, backgroundColor: `rgb(${255 - currentConfidence * 255}, ${currentConfidence * 255}, 0)` }}>
                                                    <span className="confidence-text">{(currentConfidence * 100).toFixed(2)}%</span>
                                                </div>
                                            </div>
                                        </div>
                                            : <>
                                            </>}
                                        {currentLatexData.text !== '' ?
                                            <div style={{ textAlign: 'left' }}>
                                                {currentLatexData.html ? parse(currentLatexData.html, { replace: handleLatexTag }) :
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        height: '30vh'
                                                    }}>
                                                        <l-dot-pulse
                                                            size="60"
                                                            speed="1.3"
                                                            color="#8A67F3"
                                                        ></l-dot-pulse>
                                                    </div>
                                                }
                                            </div> :
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: '30vh'
                                            }}>
                                                <l-dot-pulse
                                                    size="60"
                                                    speed="1.3"
                                                    color="#8A67F3"
                                                ></l-dot-pulse>
                                            </div>
                                        }

                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="column">
                        <div className="solution-container">
                            <div className="solution-header">
                                <div style={{ display: 'flex', alignItems: 'center', height: '8vh', justifyContent: 'space-between', width: '100%' }}>
                                    <span className="transcription-text">Our Approach</span>
                                    {gpt4ResponseArray[currentProblemIndex].length > 1 && (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <button className="chevron-button-small"
                                                onClick={() => setCurrentSolutionIndex(currentIndex =>
                                                    currentIndex - 1 < 0 ? gpt4ResponseArray[currentProblemIndex].length - 1 : currentIndex - 1)}>
                                                <ArrowBackIosNewIcon style={{ color: "#7B41D9", fontSize: '15px' }} />
                                            </button>
                                            <span className="counter" style={{ margin: '0 8px' }}> {/* Adjust the margin as needed for spacing */}
                                                {currentSolutionIndex + 1}/{gpt4ResponseArray[currentProblemIndex].length}
                                            </span>
                                            <button className="chevron-button-small"
                                                onClick={() => setCurrentSolutionIndex(currentIndex =>
                                                    (currentIndex + 1) % gpt4ResponseArray[currentProblemIndex].length)}>
                                                <ArrowForwardIosIcon style={{ color: "#7B41D9", fontSize: '15px' }} />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {isSolutionExpanded && (
                                <div className="solution-content">
                                    {currentGpt4Response ? renderGpt4Response() : (
                                        <div className="solution-loading-content" style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '50vh' // Ensure this container has a defined height
                                        }}>
                                            <l-quantum
                                                size="110"
                                                speed="1.5"
                                                color="#8A67F3"
                                            ></l-quantum>
                                        </div>
                                    )}
                                    <div className="solve-again-button-container" style={{ marginTop: "20px", textAlign: "center" }}>
  {Array.isArray(gpt4ResponseArray[currentProblemIndex]) && gpt4ResponseArray[currentProblemIndex].length > 0 && !retryAttemptedStatus[currentProblemIndex] &&
    <button
      className="retry-button"
      disabled={isButtonDisabled[currentProblemIndex]}
      onClick={() => handleSolveAgain(uniqueComponentId, currentProblemIndex)}>
      {isButtonDisabled[currentProblemIndex] ? "Generating AI's second response, this may take a second" : "Have AI try to solve it again? (free)"}
    </button>
  }
</div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProblemsSolved;
