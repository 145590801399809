import React from 'react';
import './ResponsiveWarningPopup.css';

const ResponsiveWarningPopup = () => {
  return (
    <div className="responsive-warning-popup">
      <p className="main-message"><strong><em>Quicksolve</em></strong>.ai is currently a web app.</p>
      <p className="secondary-message">Maximize your screen!</p>
      <p className="small-text">(we're currently working on making the application responsive for phone and tablet).</p>
    </div>
  );
};

export default ResponsiveWarningPopup;
