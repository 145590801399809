import React, { useState, useEffect } from "react";
import Header from "../anonymous_components/Header";
import Upload from "../anonymous_components/Upload";
import Footer from "../anonymous_components/Footer";
import Cards from "../anonymous_components/Cards";
import Login from "../anonymous_components/Login";
import SignUp from "../anonymous_components/Signup";
import LoggedInHeader from "../loggedin_components/LoggedInHeader";
import LoggedInUpload from "../loggedin_components/LoggedInUpload";
import SectionTitle from '../loggedin_components/SectionTitle'; 
import "./HomePage.css";
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../UserAuthContext';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import DraftsComponent from "../loggedin_components/DraftsComponent";
import NoAccessPage from "./NoAccessPage";
import ResponsiveWarningPopup from "./ResponsiveWarningPopup";

const HomePage = () => {
    const navigate = useNavigate();
    const [showOverlay, setShowOverlay] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const [loading, setLoading] = useState(true);
    const { user, loggedOut } = useUserAuth();
    const [userName, setUserName] = useState(null);
    const [draftsLoaded, setDraftsLoaded] = useState(false);

    const toggleOverlay = () => {
        setShowOverlay(!showOverlay);
        if (showSignUp) {
            setShowSignUp(false);
        }
    };

    const handleBackToLogin = () => {
        setShowSignUp(false);
        setShowOverlay(true);
    };

    // Callback for when DraftsComponent has loaded data
    const handleDraftsDataLoaded = () => {
        setDraftsLoaded(true);
    };

    const [showResponsivePopup, setShowResponsivePopup] = useState(false);

    useEffect(() => {
        const updateScreenResponse = () => {
            if (window.innerWidth < 1024) {
                setShowResponsivePopup(true);
            } else {
                setShowResponsivePopup(false);
            }
        };

        window.addEventListener("resize", updateScreenResponse);
        updateScreenResponse(); // Call it on mount to check initial size

        return () => window.removeEventListener("resize", updateScreenResponse);
    }, []);
  
    const fetchUserName = async (userId) => {
      const userDocRef = doc(db, "users", userId, "Account", "userinfo");
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
          return userDocSnap.data().name;
      } else {
          console.error("No user data found in Firestore!");
          return null;
      }
  };

  useEffect(() => {
    if (user && user.id) {
        (async () => {
            const fetchedName = await fetchUserName(user.id);
            const firstName = fetchedName ? fetchedName.split(' ')[0] : null;
            setUserName(firstName);
            setLoading(false); // Set loading to false here
        })();
    } else {
        setLoading(false); // Set loading to false here as well
    }
}, [user]);


  // Show loading screen while waiting for user auth
  if (((loading) || (user && !userName)) && !loggedOut) {
    return (
        <NoAccessPage isPromptActive={false} />
  );
  }

  if (user && userName) {
      return (
          <div className="loggedInHomePage">
            {showResponsivePopup && <ResponsiveWarningPopup />}
              <LoggedInHeader name={userName} />
              <LoggedInUpload navigate={navigate}/>
              <SectionTitle boldText="PAST UPLOADS" normalText=", get back to where you left off" />
              <DraftsComponent />
          </div>
      );
  }

  if (!loading) return (
      <div className="homePage">
          {showOverlay && !showSignUp && <Login onClose={toggleOverlay} onSignUpClick={() => setShowSignUp(true)} />}
          {showSignUp && <SignUp onClose={toggleOverlay} onLoginClick={handleBackToLogin} />}
          <Header toggleOverlay={toggleOverlay} />
          <Upload navigate={navigate} />
          <Cards />
          <Footer />
      </div>
  );
};

export default HomePage;
