import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Solved from './loggedin_components/Solved';
import Tokens from './loggedin_components/Tokens';
import ProblemSelection from './pages/ProblemSelection';
import ProblemsSolved from './loggedin_components/ProblemsSolved';
import NoAccessPage from './pages/NoAccessPage';
import ResponsiveWarningPopup from './pages/ResponsiveWarningPopup';
import EmailGenerator from './pages/EmailGenerator';
import pdFEGenerator from './pages/PdFEGenerator';
import { SessionProvider } from './SessionContext';
import { UserAuthProvider, useUserAuth } from './UserAuthContext';
import './App.css';
import PdFEGenerator from './pages/PdFEGenerator';

// ResponsiveWarningHandler Component
const ResponsiveWarningHandler = () => {
  const { user } = useUserAuth(); // Access user auth state
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const checkScreenWidthAndUser = () => {
      // Only show popup if screen width is < 1024px and user is logged in
      // and not on the anonymous version of HomePage ("/")
      const shouldShowPopup = window.innerWidth < 1024 && user && location.pathname !== "/";
      setShowPopup(shouldShowPopup);
    };

    checkScreenWidthAndUser(); // Check immediately on mount
    window.addEventListener('resize', checkScreenWidthAndUser); // Listen for resize events

    // Cleanup
    return () => window.removeEventListener('resize', checkScreenWidthAndUser);
  }, [location.pathname, user]); // Re-run effect if route changes or user authentication state changes

  return showPopup ? <ResponsiveWarningPopup /> : null;
};
// App Component
function App() {
  return (
    <UserAuthProvider>
      <SessionProvider>
        <Router>
          <div className="App">
            <ResponsiveWarningHandler /> {/* Use the handler component */}
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/ProblemSelection" element={<ProblemSelection />} />
              <Route path="/solved" element={<Solved />} />
              <Route path="/ProblemsSolved/:uniqueComponentId" element={<ProblemsSolved />} />
              <Route path="/Tokens" element={<Tokens />} />
              <Route path="/no-access" element={<NoAccessPage />} />
              <Route path="/email-generator" element={<EmailGenerator />} />
              <Route path="/pd-fe-generator" element={<PdFEGenerator />} />
            </Routes>
          </div>
        </Router>
      </SessionProvider>
    </UserAuthProvider>
  );
}

export default App;
