// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getAuth, signOut } from "firebase/auth";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD-2OCTBrFH5rgqNSmk8-LctT2ww_plTOk",
  authDomain: "quick-solve-ai.firebaseapp.com",
  projectId: "quick-solve-ai",
  storageBucket: "quick-solve-ai.appspot.com",
  messagingSenderId: "467857080329",
  appId: "1:467857080329:web:a2e5cbb2b8e0d3b6aeb8d8",
  measurementId: "G-WG73BDEFC2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

// auth
const auth = getAuth(app);

// functions
const functions = getFunctions(app);

export { app, analytics, db, auth, signOut, functions };
