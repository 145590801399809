import React, { useState, useCallback, useRef } from 'react';
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { runTransaction, doc } from 'firebase/firestore';
import { app, db } from '../firebaseConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import './LoggedInUpload.css';
import heic2any from 'heic2any';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { auth } from '../firebaseConfig'; 

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
const validFileTypes = ['.jpeg', '.jpg', '.png', '.webp', '.heic', '.pdf'];

const Upload = ({ navigate }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [originalFileProgresses, setOriginalFileProgresses] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [originalFileMapping, setOriginalFileMapping] = useState({});

  // Fetch the user's uid from Firebase Auth
  const userUid = auth.currentUser ? auth.currentUser.uid : null;

  const updateFirestoreWithFile = useCallback(async (uniqueId, fileName) => {
    if (!userUid) {
      console.error("User is not logged in. Cannot update Firestore.");
      return;
    }
    const docRef = doc(db, "users", userUid, "Drafts", uniqueId);
    await runTransaction(db, async (transaction) => {
      const docSnap = await transaction.get(docRef);
      const fileData = docSnap.data();
      let updatedFiles = fileData && fileData.files ? [...fileData.files, fileName] : [fileName];
      const creationDate = new Date().toISOString(); // Current date and time
      transaction.set(docRef, { files: updatedFiles, creationDate });
    });
  }, [userUid]);
  

  const uploadAndLogFile = useCallback(async (uniqueId, file) => {
    console.log(`Starting upload for file: ${file.name} with uniqueId: ${uniqueId}`);
  
    const storage = getStorage(app);
    const storageRef = ref(storage, `UserUploads/${userUid}/${uniqueId}/CurrentUploads/${file.name}`);
  
    console.log(`Created storage reference for file: ${file.name}`);
  
    // Await ensures the upload finishes before proceeding
    await new Promise((resolve, reject) => {
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on('state_changed',
        snapshot => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          const originalFileName = originalFileMapping[file.name] || file.name;
          console.log(`Uploading ${originalFileName}: ${progress}% done`);
          
          // Existing code to handle progress
        },
        error => {
          console.error(`Failed to upload ${file.name}:`, error);
          reject(error);
        },
        () => {
          console.log(`Successfully uploaded ${file.name}`);
          resolve();
        }
      );
    });
  
    // After the file is uploaded, log it to Firestore
    console.log(`Logging ${file.name} to Firestore`);
    await updateFirestoreWithFile(uniqueId, file.name);
    console.log(`Successfully logged ${file.name} to Firestore`);
  }, [originalFileMapping, userUid, updateFirestoreWithFile]);
  

  const fileInput = useRef(null);

  const isValidFileType = useCallback((fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return validFileTypes.includes(`.${fileExtension}`);
}, []);


  const UPLOAD_ERROR_TIMEOUT = 1500;

  const handleFileChange = useCallback(async (e) => {
    const uniqueId = uuidv4();
    sessionStorage.setItem('uniqueComponentId', uniqueId);
    const { files: eventFiles } = e.target;
    const files = Array.from(eventFiles);
    
    // Validate file types
    const invalidFiles = files.filter(file => !isValidFileType(file.name));
    if (invalidFiles.length > 0) {
      const errorMessage = `Unsupported file types: ${invalidFiles.map(file => file.name).join(', ')}`;
      setErrorMessage(errorMessage);
      setTimeout(() => setErrorMessage(null), UPLOAD_ERROR_TIMEOUT);
      return;
    }
    
    const convertedFiles = [];
    let tempMapping = {}; // Temporary mapping for this upload session
    
    // Convert files if necessary
    for (const file of files) {
      if (['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
        // Handle standard image files
        const img = new Image();
        img.src = URL.createObjectURL(file);
        await new Promise((resolve) => img.onload = resolve);
    
        const resizedBlob = await resizeImageIfNeeded(img);
        if (resizedBlob) {
          const resizedFile = new File([resizedBlob], file.name, { type: "image/jpeg" });
          convertedFiles.push(resizedFile);
        } else {
          convertedFiles.push(file);
        }
    
        URL.revokeObjectURL(img.src); // Clean up object URL 
      } else if (file.name.endsWith('.pdf')) {
        const imageBlobs = await convertPDFToImages(file);
        for (const [index, blob] of imageBlobs.entries()) {
          const newFileName = `${file.name}_page${index + 1}.png`;
          convertedFiles.push(new File([blob], newFileName, { type: "image/png" }));
          tempMapping[newFileName] = file.name; // Add to our mapping
        }
      } else if (file.type === "image/heic") {
        const jpegFile = await convertHeicToJPEG(file);
        convertedFiles.push(jpegFile);
        tempMapping[jpegFile.name] = file.name; // Add to our mapping
      } else {
        convertedFiles.push(file);
        tempMapping[file.name] = file.name; // In this case, the original and converted names are the same.
      }
    }
    
    setOriginalFileMapping(tempMapping); // Set our state with the mapping for this session
  
    if (convertedFiles.length > 0) {
      setIsUploading(true);
    
      // Serialize uploads to ensure order is maintained
      const uploadSequentially = convertedFiles.reduce((prevPromise, file) => {
        return prevPromise.then(() => uploadAndLogFile(uniqueId, file));
      }, Promise.resolve());
    
      uploadSequentially
        .then(() => {
          console.log("Upload successful, navigating...");
          navigate('/ProblemSelection');
        })
        .catch(error => {
          console.error('Some uploads or logs failed:', error);
        })
        .finally(() => {
          console.log("Upload sequence finished.");
          setIsUploading(false);
          setOriginalFileProgresses({}); // Reset the progress
        });
    }
  }, [navigate, isValidFileType, uploadAndLogFile]);
  


  const convertPDFToImages = async (file) => {
    const blobs = [];

    const loadingTask = pdfjsLib.getDocument({ data: await file.arrayBuffer() });
    const pdfDocument = await loadingTask.promise;

    for (let pageNum = 1; pageNum <= pdfDocument.numPages; pageNum++) {
      const page = await pdfDocument.getPage(pageNum);
      const viewport = page.getViewport({ scale: 1.0 });

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext).promise;

      // Convert canvas to blob and push to the array
      const blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/png'));
      blobs.push(blob);
    }

    return blobs;
  };


  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    handleFileChange({ target: { files } });
  };

  const convertHeicToJPEG = async (file) => {
    if (file.type === "image/heic") {
      const heicBlob = new Blob([await file.arrayBuffer()], { type: "image/heic" });

      const jpegBlob = await heic2any({
        blob: heicBlob,
        toType: "image/jpeg",
        quality: 0.77
      });

      const newFile = new File([jpegBlob], file.name.replace(/\.heic$/, '.jpeg'), {
        type: "image/jpeg"
      });

      return newFile;
    }

    return file;
  };

  const resizeImageIfNeeded = async (image) => {
    const MIN_DIMENSION = 800;
    let { width, height } = image;
  
    // Check if resizing is needed
    if (width >= MIN_DIMENSION && height >= MIN_DIMENSION) {
      return null; // No resizing needed
    }
  
    // Calculate new dimensions
    if (width < height) {
      height = height * (MIN_DIMENSION / width);
      width = MIN_DIMENSION;
    } else {
      width = width * (MIN_DIMENSION / height);
      height = MIN_DIMENSION;
    }
  
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0, width, height);
  
    return new Promise((resolve) => {
      canvas.toBlob(resolve, 'image/jpeg');
    });
  };

  return (
    <div className='loggedInUpload'>
        <input
            type="file"
            multiple
            ref={fileInput}
            style={{ display: 'none' }}
            onChange={handleFileChange}
        />
        <div className='loggedInSectionRow'>
            <div className='loggedInWink'>😉</div>
            <div className='loggedInLastElement'>
                <div className='loggedInUploadArea'
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}>
                    {isUploading ? (
                        <>
                            <div>Uploading...</div>
                            <div>
                                {[...new Set(Object.values(originalFileMapping))].map(originalFile => (
                                    <div key={originalFile}>
                                        {originalFile}: {Math.round(originalFileProgresses[originalFile] || 0)}%
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='loggedInDragAndDrop'>
                                Upload! Drag and Drop your file(s) here
                            </div>
                            <div className='loggedInOr'>- or -</div>
                            <div className='loggedInBrowseButton' onClick={() => fileInput.current.click()}>
                                Browse <FontAwesomeIcon icon={faFolderOpen} size="sm" style={{ marginLeft: '5px' }} />
                            </div>
                        </>
                    )}
                </div>
                <div className={errorMessage ? 'loggedInSupportedFiles error' : 'loggedInSupportedFiles'}>
                    {errorMessage || 'supported files: .pdf .jpeg .jpg .png .heic'}
                </div>
            </div>
        </div>
    </div>
);
};

export default Upload;