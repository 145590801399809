import React, { useEffect, useState } from 'react';
import './DraftsComponent.css';
import { db, auth, app } from '../firebaseConfig'; 
import { getDocs, collection, deleteDoc, doc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, listAll, deleteObject } from "firebase/storage";  
import { useNavigate } from 'react-router-dom';
import { dotPulse } from 'ldrs';

dotPulse.register();

const DraftsComponent = () => {
    const [drafts, setDrafts] = useState([]);
    const [isLoading, setIsLoading] = useState(true); 
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [selectedDraftId, setSelectedDraftId] = useState(null);

    const openModal = (draftId) => {
        setShowModal(true);
        setSelectedDraftId(draftId);
    }
    
    const closeModal = () => {
        setShowModal(false);
        setSelectedDraftId(null);
    }

    const handleClick = (draftId) => {
        sessionStorage.setItem('uniqueComponentId', draftId);
        navigate('/problemSelection'); 
    };

    const handleRemove = async (draftId) => {
        try {
            const user = auth.currentUser;
            if (user) {
                // Delete from Firestore
                const draftRef = doc(db, `users/${user.uid}/Drafts`, draftId);
                await deleteDoc(draftRef);
    
                // Delete from Firebase Storage
                const storage = getStorage(app);
                const folderRef = ref(storage, `UserUploads/${user.uid}/${draftId}`);
    
                // List all items in the folder
                const { items, prefixes } = await listAll(folderRef);
    
                // Delete each item
                for (const item of items) {
                    await deleteObject(item);
                }
    
                // Recursively delete items in subfolders
                for (const prefix of prefixes) {
                    await deleteFolderContents(prefix);
                }
    
                // Remove from local state
                setDrafts((prevDrafts) => prevDrafts.filter((draft) => draft.id !== draftId));
            }
        } catch (error) {
            console.error("Error deleting draft: ", error);
        }
    };
    
    // Helper function to delete contents of a folder
    const deleteFolderContents = async (folderRef) => {
        const { items, prefixes } = await listAll(folderRef);
    
        for (const item of items) {
            await deleteObject(item);
        }
    
        for (const prefix of prefixes) {
            await deleteFolderContents(prefix);
        }
    };
    

    const formatDate = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const nth = (d) => {
            if (d > 3 && d < 21) return 'th';
            switch (d % 10) {
                case 1: return "st";
                case 2: return "nd";
                case 3: return "rd";
                default: return "th";
            }
        }

        return `${monthNames[date.getMonth()]} ${date.getDate()}${nth(date.getDate())}, ${date.getFullYear()}`;
    }

    useEffect(() => {
        const fetchDrafts = async () => {
            const user = auth.currentUser;
            const storage = getStorage();  // Initialize Firebase Storage

            if (user) {
                const uid = user.uid;

                const draftsCollection = collection(db, `users/${uid}/Drafts`);
                const draftsSnapshot = await getDocs(draftsCollection);

                const draftsData = await Promise.all(draftsSnapshot.docs.map(async doc => {
                    const files = doc.data().files;
                    const firstFile = files.length > 0 ? files[0] : null;  // Get the first file name

                    // Construct path to the image in Firebase Storage
                    const imagePath = `UserUploads/${uid}/${doc.id}/CurrentUploads/${firstFile}`;
                    const imageRef = ref(storage, imagePath);

                    let imageUrl = null;

                    try {
                        imageUrl = await getDownloadURL(imageRef);  // Get download URL for the image
                    } catch (error) {
                        console.error("Error fetching image: ", error);
                    }

                    return {
                        id: doc.id,
                        creationDate: new Date(doc.data().creationDate),
                        numberOfFiles: files.length,
                        firstFile,
                        imageUrl  // Include the image URL in the draft object
                    };
                }));

                draftsData.sort((a, b) => b.creationDate - a.creationDate);
                setDrafts(draftsData);
                setIsLoading(false);
            }
        };

        fetchDrafts();
    }, []);

    return (
        <div className="drafts-container">
            {isLoading ? (
            <div className="loading-container"> 
                <l-dot-pulse
                    size="60"
                    speed="1.8"
                    color="#8A67F3"
                ></l-dot-pulse>
            </div>
        ) :
            drafts.length === 0 ? (
                <div className="nothing-here-background">
                <p className="nothing-here">Oops, you don't have any drafts yet.</p>
                <p className="nothing-here-secondary">Get creating, Upload up above!</p>
                <span className="emoji-bottom-right">😭</span>
                </div>
            ) : (
                drafts.map((draft, index) => (
                    <button key={index} className="draft-item" onClick={() => handleClick(draft.id)}>
                        <button className="remove-button" onClick={(e) => { e.stopPropagation(); openModal(draft.id); }}>&ndash;</button>
                        <div className="draft-item-info files">
                            {draft.numberOfFiles} Files
                        </div>
                        <div className="draft-item-info date">
                            {formatDate(draft.creationDate)}
                        </div>
                        <div className="draft-item-preview">
                            {draft.imageUrl && <img src={draft.imageUrl} alt={draft.firstFile} />}  {/* Display the image */}
                        </div>
                    </button>
                ))
            )}
    
            {showModal && (
                <div className="modal-background">
                    <div className="modal-content">
                    <p className="modal-text">Are you sure you want to delete this draft?</p>
                        <div className="button-group">
                        <button className="draft-delete-nah"onClick={closeModal}>No, never mind</button>
                        <button className="draft-delete-confirmation" onClick={() => { handleRemove(selectedDraftId); closeModal(); }}>Yes, I'm sure</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
    
};

export default DraftsComponent;
