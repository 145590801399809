import React, { useEffect, useState, useRef, useCallback } from 'react';
import './LoggedInHeader.css';
import { useUserAuth } from '../UserAuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faGear, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { db, auth } from '../firebaseConfig'; // Import db from your Firebase config file
import { doc, getDoc} from "firebase/firestore";
import { onAuthStateChanged } from 'firebase/auth';


const LoggedInHeader = ({ name }) => {
    const { user, logOut } = useUserAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const [activeButton, setActiveButton] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [numTokens, setNumTokens] = useState(null); // State to store the user's token balance

    const profileButtonRef = useRef(null);
    const dropdownRef = useRef(null); // Create a ref for the dropdown container

    const handleProfileClick = () => {
        setShowDropdown(prev => !prev);
    };

    const handleDocumentClick = useCallback((e) => {
        if (showDropdown && dropdownRef.current && !dropdownRef.current.contains(e.target) && !profileButtonRef.current.contains(e.target)) {
            setShowDropdown(false);
        }
    }, [showDropdown]);

    useEffect(() => {
        switch (location.pathname) {
            case '/':
                setActiveButton('home');
                break;
            case '/solved':
                setActiveButton('solved');
                break;
            case '/tokens':
                setActiveButton('tokens');
                break;
            default:
                setActiveButton('');
                break;
        }
        document.addEventListener('mousedown', handleDocumentClick);
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [location.pathname, showDropdown, handleDocumentClick]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
          if (currentUser) {
            // Your existing logic to fetch tokens
            const docRef = doc(db, 'users', currentUser.uid, 'Account', 'balance');
            getDoc(docRef)
              .then((docSnapshot) => {
                if (docSnapshot.exists()) {
                  setNumTokens(docSnapshot.data().numTokens || 0);
                } else {
                  setNumTokens(0);
                }
              })
              .catch((error) => {
                console.error('Error fetching token balance:', error);
              });
          } else {
            console.log("User is not logged in");
          }
        });
      
        return () => unsubscribe();
      }, []);
      
    
         

    const handleLogout = () => {
        setNumTokens(null);
        logOut();
        navigate('/');
      };
      

    const handleSolved = () => {
        navigate('/solved');
        sessionStorage.setItem('username', name);
    };

    const handleTokens = () => {
        sessionStorage.setItem('username', name);
        navigate('/tokens');
    };

    const handleHome = () => {
        navigate('/');
    }

    return (
        <div className="loggedin-header">
            <div className="loggedin-header-greeting">
                <span className="loggedin-greeting">Howdy,</span>
                <span className="loggedin-username">{name}</span>
            </div>
            <div className="loggedin-header-actions">
                <button className={`loggedin-button get-help ${activeButton === 'home' ? 'active' : ''}`} onClick={handleHome}>get help 😁</button>
                <button className={`loggedin-button solved ${activeButton === 'solved' ? 'active' : ''}`} onClick={handleSolved}>solved. ✅</button>
                <button className={`loggedin-button tokens ${activeButton === 'tokens' ? 'active' : ''}`} onClick={handleTokens}>
  {numTokens !== null ? `${numTokens} tokens` : 'tokens'}
</button>
                <button 
                    className="loggedin-profile" 
                    onClick={handleProfileClick} 
                    ref={profileButtonRef}
                    style={showDropdown ? { backgroundColor: '#DAC7F869', border: '1px solid white' } : {}}
                >
                    <span className="loggedin-profileicon">
                        <FontAwesomeIcon 
                            icon={faUser} 
                            style={showDropdown ? { color: "white" } : { color: "#7842D8" }} 
                            size="lg" 
                        />
                    </span>
                </button>
            </div>
            {showDropdown && (
                <div className="dropdown-container" ref={dropdownRef} style={{ top: `${profileButtonRef.current?.offsetHeight + 30}px`, right: '5%' , padding: 0 }}>
                    <div className="dropdown-item" >
                        <span ><FontAwesomeIcon icon={faGear} style={{ color: "#7842D8", marginRight: '1.2rem', marginLeft:'1.5rem'}} size="0.3x" /></span>
                        <p className="dropdown-item-text" > Account Settings</p>
                    </div>
                    <div className="dropdown-item logout" onClick={handleLogout}>
                        <span ><FontAwesomeIcon icon={faRightFromBracket} style={{ color: "#E33333", marginRight: '1.2rem', marginLeft:'1.5rem'}} size="0.3x" /></span>
                        <p className="dropdown-item-text" > Log Out </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LoggedInHeader;
