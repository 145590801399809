import React, { useState } from 'react';
import './Signup.css';
import googleimg from '../assets/googlelogo.png';
import { auth, db } from '../firebaseConfig'; 
import { doc, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useUserAuth } from '../UserAuthContext';

const createUserDataInFirestore = async (userId, userEmail, userName) => {
    try {
        // Set user info in the 'userinfo' subcollection of 'Account'
        await setDoc(doc(db, "users", userId, "Account", "userinfo"), {
            name: userName,
            email: userEmail
        });

        // Set the initial balance in the 'balance' subcollection of 'Account'
        await setDoc(doc(db, "users", userId, "Account", "balance"), {
            numTokens: 0
        });

        // // Placeholder for 'Drafts' collection
        // await setDoc(doc(db, "users", userId, "Drafts", "placeholder"), {
        //     placeholder: true
        // });

        // // Placeholder for 'Solved' collection
        // await setDoc(doc(db, "users", userId, "Solved", "placeholder"), {
        //     placeholder: true
        // });

    } catch (error) {
        console.error("Error writing user data to Firestore:", error);
    }
}

const SignUp = ({ onClose, onLoginClick }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const { setUser } = useUserAuth();

    const handleSignUp = async () => {
        setNameError("");
        setEmailError("");
        setPasswordError("");
        setConfirmPasswordError("");

        if (!name.trim()) {
            setNameError("Name is required.");
            return;
        }
        if (!email.trim()) {
            setEmailError("Email is required.");
            return;
        }
        if (!password.trim()) {
            setPasswordError("Password is required.");
            return;
        }
        if (password !== confirmPassword) {
            setConfirmPasswordError("Passwords do not match!");
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);

            // On successful account creation, populate Firestore
            await createUserDataInFirestore(userCredential.user.uid, email, name);

            setUser({
                id: userCredential.user.uid,
                name: name,
                email: email
            });

            onClose();
        } catch (error) {
            switch (error.code) {
                case "auth/email-already-in-use":
                    setEmailError("This email is already in use.");
                    break;
                case "auth/invalid-email":
                    setEmailError("The email address is not valid.");
                    break;
                case "auth/operation-not-allowed":
                    setEmailError("Email and password accounts are not enabled. Contact support.");
                    break;
                case "auth/weak-password":
                    setPasswordError("Password must be 6 characters long.");
                    break;
                default:
                    setEmailError("An unknown error occurred. Please try again.");
                    break;
            }
        }        
    };

    const handleGoogleSignUp = async () => {
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);

            // On successful account creation with Google, populate Firestore
            await createUserDataInFirestore(result.user.uid, result.user.email, result.user.displayName);

            setUser({
                id: result.user.uid,
                name: result.user.displayName,
                email: result.user.email,
            });

            onClose();
        } catch (error) {
            console.log(error);
        }
    };    

    return (
        <div className="signup-overlay">
            <div className="signup-container">
                <button className="signup-close" onClick={onClose}>
                    X
                </button>
                <div className="signup-welcome-container">
                    <span className="signup-welcome-text">Join</span>
                    <br />
                    <span className="signup-brand-name">QuickSolve</span>
                </div>

                <div className="signup-input-group">
                    <label className="signup-name-label">Name</label>
                    <input type="text" className="signup-name-input" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter your name" />
                    {nameError && <div style={{ color: 'red', fontSize: '14px' }}>{nameError}</div>}
                </div>

                <div className="signup-input-group">
                    <label className="signup-email-label">Email</label>
                    <input type="email" className="signup-email-input" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" />
                    {emailError && <div style={{ color: 'red', fontSize: '14px' }}>{emailError}</div>}
                </div>

                <div className="signup-input-group">
                    <label className="signup-password-label">Password</label>
                    <input type="password" className="signup-password-input" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter your password" />
                    {passwordError && <div style={{ color: 'red', fontSize: '14px' }}>{passwordError}</div>}
                </div>

                <div className="signup-input-group">
                    <label className="signup-confirm-password-label">Confirm Password</label>
                    <input type="password" className="signup-confirm-password-input" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm your password" />
                    {confirmPasswordError && <div style={{ color: 'red', fontSize: '14px' }}>{confirmPasswordError}</div>}
                </div>

                <div className="signup-centered-container">
                    <div className="signup-button" onClick={handleSignUp}>
                        <span>Sign Up</span>
                    </div>
                    <div className="signup-separator">--- OR ---</div>
                    <button className="signup-google-login" onClick={handleGoogleSignUp}>
                        <img src={googleimg} alt="Google Logo" />
                        <span>Continue with Google</span>
                    </button>
                    <button className="signup-login-prompt" onClick={onLoginClick}>
                        already have an account? <span className="signup-bold-login">Log In</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
