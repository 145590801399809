
import React, { useEffect, useState } from 'react';
import LoggedInHeader from './LoggedInHeader';
import './Tokens.css';
import { auth } from '../firebaseConfig';

import { spiral } from 'ldrs'

import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

import image25 from '../assets/25.png';
import image50 from '../assets/50.png';
import image100 from '../assets/100.png';




spiral.register()

//test publishable key
const stripePromise = loadStripe('pk_live_51NwDlMKuFkSCVKkQNCLa5aIVanD9poZX7COE5GorgoXtpkO9LVrvX0dqunWSY5Q3xYReuZuWuX686eLDh9WoO6JL00ZkhZxlNa');

export default function Tokens() {
    const [username, setUsername] = useState('');
    const [userId, setUserId] = useState('');
    const [loadingStatus, setLoadingStatus] = useState({
        '25_tokens': false,
        '50_tokens': false,
        '100_tokens': false
    });
    const [showCodeArea, setShowCodeArea] = useState(true);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    const handleSubmitCode = async () => {
        const code = document.querySelector('.code-input').value;
        if (!code.trim()) {
            setMessage("Please enter a code.");
            setMessageType("error");
            setTimeout(() => {
                setMessage('');
                setMessageType('');
            }, 3000); // Clear message after 3 seconds
            return;
        }

        const functions = getFunctions();
        const redeemCode = httpsCallable(functions, 'redeemCode');

        redeemCode({ code })
            .then((result) => {
                // Handle the success response
                console.log(result); // Logging the result for debugging
                setMessage("Code successfully redeemed!");
                setMessageType("success");
                setShowCodeArea(false);
                setTimeout(() => window.location.reload(), 500);
            })
            .catch((error) => {
                // Handle the error response
                console.error(error); // Logging the error for debugging
                setMessage(error.message || "Failed to redeem the code. Please try again.");
                setMessageType("error");
            })
            .finally(() => {
                setTimeout(() => {
                    setMessage('');
                    setMessageType('');
                }, 3000); // Clear message after 3 seconds
            });
    };

    useEffect(() => {
        const checkRedeemedStatus = async () => {
            if (userId) {
                const db = getFirestore();
                const balanceRef = doc(db, `users/${userId}/Account/balance`);
                const docSnap = await getDoc(balanceRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    if (data.redeemedFree) {
                        setShowCodeArea(false); // Hide the code area if redeemedFree is true
                    }
                }
            }
        };
    
        checkRedeemedStatus();
    }, [userId]); // Rerun this effect if userId changes
    

    useEffect(() => {
        // Reset loading status when component mounts
        setLoadingStatus({
            '25_tokens': false,
            '50_tokens': false,
            '100_tokens': false
        });
    }, []);


    useEffect(() => {
        const savedUsername = sessionStorage.getItem('username');
        if (savedUsername) {
            setUsername(savedUsername);
        }

        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setUserId(user.uid);
            } else {
                // Handle the case when the user is not signed in if necessary
            }
        });

        return () => unsubscribe(); // Clean up subscription on unmount
    }, []);

    const handleCheckout = async (priceId, tokenAmount) => {
        if (!userId) {
            console.error("User is not signed in");
            return;
        }

        setLoadingStatus(prev => ({ ...prev, [tokenAmount]: true }));


        try {
            console.log("Sending request for user ID:", userId); // Log the user ID
            const response = await fetch('https://us-central1-quick-solve-ai.cloudfunctions.net/createCheckoutSession', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ priceId, userId }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }

            const data = await response.json();
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({ sessionId: data.id });

            if (error) {
                console.error('Error during checkout:', error);
            }

            setLoadingStatus(prev => ({ ...prev, [tokenAmount]: false }));
        } catch (error) {
            console.error('Error during checkout:', error);
            setLoadingStatus(prev => ({ ...prev, [tokenAmount]: false }));
        }
    };

    return (
        <div className="loggedInHomePage">
            <LoggedInHeader name={username} />
            <div className="tokens-container">
                <div className="tokens-header-container">
                    <h2>Invest in your learning.</h2>
                    <p>1 token = 1 problem solved</p>
                </div>
                <div className="cards-container">
                    <div className="token-card">
                        <p className="token-amount">25 tokens</p>
                        <img src={image25} alt="Token Icon" className="token-icon" />
                        <p className="token-price">$3.95</p>
                        {/* <button className="buy-button" onClick={() => handleCheckout('price_1O3Uw5KuFkSCVKkQW3A5kSxO')}>Buy</button> */}
                        <button className="buy-button" onClick={() => handleCheckout('price_1OHoL6KuFkSCVKkQw6KZNTWa', '25_tokens')}>
                            {loadingStatus['25_tokens'] ? <l-spiral size="30" speed="0.9" color="white" /> : 'Buy ($3.95)'}
                        </button>
                    </div>
                    <div className="token-card large">
                        <p className="token-amount">50 tokens</p>
                        <img src={image50} alt="Token Icon" className="token-icon" />
                        <p className="token-price">$5.95</p>
                        <button className="buy-button" onClick={() => handleCheckout('price_1OHoM3KuFkSCVKkQdlaLJYs1', '50_tokens')}>
                            {loadingStatus['50_tokens'] ? <l-spiral size="30" speed="0.9" color="white" /> : 'Buy ($5.95)'}
                        </button>
                    </div>
                    <div className="token-card">
                        <p className="token-amount">100 tokens</p>
                        <img src={image100} alt="Token Icon" className="token-icon" />
                        <p className="token-price">$9.95</p>
                        <button className="buy-button" onClick={() => handleCheckout('price_1OHoNHKuFkSCVKkQz68xtOTb', '100_tokens')}>
                            {loadingStatus['100_tokens'] ? <l-spiral size="30" speed="0.9" color="white" /> : 'Buy ($9.95)'}
                        </button>
                    </div>
                </div>
                {showCodeArea && (
                <div className="code-area">
                    <div className="code-input-section">
                        <input className="code-input" placeholder="Have a code?" />
                        <button className="submit-code-button" onClick={handleSubmitCode}>🤫</button>
                    </div>
                    <div className={`message ${messageType}`}>{message}</div>
                </div>
                )}
            </div>
        </div>
    );
}
