import React, { useState } from 'react';
import './EmailGenerator.css';

function PdFEGenerator() {
    const [personName, setPersonName] = useState('');
    const [yourName, setYourName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [recipientEmail, setRecipientEmail] = useState('');
    const [generatedLink, setGeneratedLink] = useState('');
    const [error, setError] = useState('');

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const generateLink = () => {
        if (!personName || !yourName || !companyName || !phone || !email || !recipientEmail) {
            setError('All fields must be filled out.');
            return;
        }

        if (!validateEmail(email) || !validateEmail(recipientEmail)) {
            setError('Please enter valid email addresses.');
            return;
        }

        setError('');

        const subject = encodeURIComponent(`${companyName}’s Invitation to Host Professional Development Event with the Hispanic Business Student Association`);
        const body = encodeURIComponent([
            `Dear ${personName},`,
            ``,
            `My name is ${yourName} and I am with the Professional Development Committee for the Hispanic Business Student Association (HBSA) at the University of Texas at Austin. I am writing to see if you and your colleagues would be interested in hosting a Professional Development Event with us this upcoming Fall. No sponsorship is required. Our PD events are marketed across the McCombs School of Business and are not limited to HBSA members. If you are interested, we can provide specific times and dates for you and your team to choose from.`,
            ``,
            `Our PD Events aim to professionally develop our highly valuable pool of members from different backgrounds by providing workshops and one-on-one networking opportunities with companies of their interest. In addition, it also gives ${companyName} an opportunity to identify good candidates for recruitment season during the busiest weeks of Spring recruitment at UT. Attached is an infographic about HBSA to provide reference material.`,
            ``,
            `I look forward to hearing back from you. Thank you in advance on behalf of the Professional Development Team and if you have any doubts or concerns do not hesitate to reach me at ${phone} or ${email}.`,
            ``,
            `Kind Regards,`,
            `${yourName}`
        ].join('\n'));

        const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${recipientEmail}&su=${subject}&body=${body}`;
        setGeneratedLink(mailtoLink);
    };

    const handleEdit = () => {
        setGeneratedLink('');
    };

    const inputStyle = generatedLink ? { backgroundColor: '#d3d3d3' } : {};

    return (
        <div className="EmailGenerator">
            <h1>PD First Email Draft Generator</h1>
            <text>*disclaimer* this will not bold/add necessary attachments (ie: your signature or pd infographic)</text>
            <div className="EmailGenerator-div">
                <label htmlFor="personName" className="EmailGenerator-label">Person's Name</label>
                <input
                    id="personName"
                    type="text"
                    placeholder="Contact Name"
                    value={personName}
                    onChange={e => setPersonName(e.target.value)}
                    className="EmailGenerator-input"
                    style={inputStyle}
                    disabled={!!generatedLink}
                />
            </div>
            <div className="EmailGenerator-div">
                <label htmlFor="yourName" className="EmailGenerator-label">Your Name</label>
                <input
                    id="yourName"
                    type="text"
                    placeholder="Your Name"
                    value={yourName}
                    onChange={e => setYourName(e.target.value)}
                    className="EmailGenerator-input"
                    style={inputStyle}
                    disabled={!!generatedLink}
                />
            </div>
            <div className="EmailGenerator-div">
                <label htmlFor="companyName" className="EmailGenerator-label">Company Name</label>
                <input
                    id="companyName"
                    type="text"
                    placeholder="Company Name"
                    value={companyName}
                    onChange={e => setCompanyName(e.target.value)}
                    className="EmailGenerator-input"
                    style={inputStyle}
                    disabled={!!generatedLink}
                />
            </div>
            <div className="EmailGenerator-div">
                <label htmlFor="phone" className="EmailGenerator-label">Phone Number</label>
                <input
                    id="phone"
                    type="text"
                    placeholder="Your Phone #"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    className="EmailGenerator-input"
                    style={inputStyle}
                    disabled={!!generatedLink}
                />
            </div>
            <div className="EmailGenerator-div">
                <label htmlFor="email" className="EmailGenerator-label">Your Email</label>
                <input
                    id="email"
                    type="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className="EmailGenerator-input"
                    style={inputStyle}
                    disabled={!!generatedLink}
                />
            </div>
            <div className="EmailGenerator-div">
                <label htmlFor="recipientEmail" className="EmailGenerator-label">Recipient's Email</label>
                <input
                    id="recipientEmail"
                    type="email"
                    placeholder="Recipient's Email"
                    value={recipientEmail}
                    onChange={e => setRecipientEmail(e.target.value)}
                    className="EmailGenerator-input"
                    style={inputStyle}
                    disabled={!!generatedLink}
                />
            </div>
            {error && <p className="EmailGenerator-error">{error}</p>}
            {!generatedLink && (
                <button onClick={generateLink} className="EmailGenerator-button">Generate Link</button>
            )}
            {generatedLink && (
                <div className="EmailGenerator-buttons">
                    <button onClick={handleEdit} className="EmailGenerator-button EmailGenerator-editButton">Edit</button>
                    <a href={generatedLink} target="_blank" rel="noopener noreferrer" className="EmailGenerator-button EmailGenerator-viewDraftButton">View Draft</a>
                </div>
            )}
        </div>
    );
}

export default PdFEGenerator;
